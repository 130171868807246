import * as React from 'react';
import { ModelContext } from './context';

export interface IProps {
    modelCode: string;
}

export const ModelContextContainer: React.FC<IProps> = ({ children, modelCode }) => (
    <ModelContext.Provider value={modelCode}>
        {children}
    </ModelContext.Provider>
);
