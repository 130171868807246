import gql from 'graphql-tag';

export const INVENTORY_CAR_RESULT_FRAGMENT = gql`
    fragment InventoryCarResult on InventoryCar {
        commonOrderNumber
        imageUrls {
            exterior
            interior
            __typename
        }
        formModelName
        model {
            name
            __typename
        }
        engine {
            name
            __typename
        }
        fuelType {
            code
            __typename
        }
        salesVersion {
            name
            __typename
        }
        price {
            msrp
            __typename
        }
        numberOfWeeksToDelivery
        __typename
    }
`;