import { FormFieldFormatters } from './formTypes';
import { ICheckoutCustomerForm } from './types';

export const fieldFormatters: FormFieldFormatters<ICheckoutCustomerForm> = {
    zipcode: (v: string) =>
        v.replace(
            /^(?:(\d)\s*)(?:(\d)\s*)(?:(\d)\s*)(?:(\d)\s*)(?:(\d)\s*)$/,
            (_, ...m) => `${m[0]}${m[1]}${m[2]} ${m[3]}${m[4]}`
        )
};
