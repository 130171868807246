import * as React from 'react';
import { Click, Flex, Text } from 'vcc-ui';
import { IconInfo } from '../icon';
import { InventoryItem_configuration_options, InventoryItem_configuration_packages_options } from '../../@types/gql/designersChoice/InventoryItem';

interface IProps {
    option: InventoryItem_configuration_options
    | InventoryItem_configuration_packages_options;
    onOpen: () => void;
}

export const Option = ({ option, onOpen }: IProps) => (
    <>
        <Flex extend={{ flexDirection: 'row ' }}>
            <Text extend={{ width: '100%' }}>
                {option.name}
            </Text>
            <Click
                extend={{
                    ':hover': {
                        background: 'none',
                        color: '#666'
                    },
                    background: 'none',
                    color: '#666'
                }}
                onClick={onOpen}
            >
                <IconInfo />
            </Click>
        </Flex>
    </>
);
