import gql from 'graphql-tag';

export const CHECKOUT_STOCK_CAR_MUTATION = gql`
    mutation CheckoutStockCarMutation(
        $retailerBranchCode:String!
        $checkoutInput:CHECKOUT_INPUT!
        $customer:CUSTOMER_INPUT!
        $market:String!
        $commonOrderNumber:String!
    ){
        checkoutStockCar(
            RETAILER_BRANCH_CODE:$retailerBranchCode
            CHECKOUT_INPUT:$checkoutInput
            CUSTOMER:$customer
            MARKET:$market
            COMMON_ORDER_NUMBER:$commonOrderNumber
        ){
            success
        }
    }
`;