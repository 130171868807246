declare var GQL_BUILD_AND_PRICE: string;
declare var GQL_DESIGNERS_CHOICE: string;
declare var GQL_VCSCLOUD: string;
declare var GQL_VCSCLOUD_AUTH_HEADER: string;

export interface IConfig {
    GQL_BUILD_AND_PRICE?: string;
    GQL_DESIGNERS_CHOICE?: string;
    GQL_VCSCLOUD?: string;
    GQL_VCSCLOUD_AUTH_HEADER?: string;
}

const CONFIG: IConfig = {
    GQL_BUILD_AND_PRICE,
    GQL_DESIGNERS_CHOICE,
    GQL_VCSCLOUD,
    GQL_VCSCLOUD_AUTH_HEADER
};

export default CONFIG;
