import React from 'react';
import { Block, Flex, Text } from 'vcc-ui';
import TileGridProps from './props';
import TileGridItem from './item';
import { useCars } from '../../hooks';
import { Loader } from '../loader';

const TileGrid: React.FC<TileGridProps> = ({
    heading,
    subHeading,
    backgroundUrl,
    designersChoiceOnClick
}) => {
    const result = useCars();

    if (result.loading) {
        return (
            <Block
                extend={{
                    height: 60,
                    width: '100%',
                    position: 'relative'
                }}
            >
                <Loader />
            </Block>
        );
    } else if (!result.data?.searchInventory?.cars?.length) {
        return null;
    }

    return (
        <Flex data-autoid="designers-choice" extend={containerCSS}>
            <Flex extend={textWrapperCSS}>
                <Text
                    variant="ootah"
                    extend={headingCSS}
                    data-testid="designerschoice:heading"
                >
                    {heading}
                </Text>
                <Text
                    variant="columbus"
                    extend={subHeadingCSS}
                    data-testid="designerschoice:description"
                >
                    {subHeading}
                </Text>
            </Flex>
            <Flex extend={itemsCSS} data-autoid="designers-choice:items">
                {(result.data?.searchInventory?.cars || [])
                    .slice(0, 3)
                    .map((item, index) => (
                        <TileGridItem
                            key={index}
                            index={index}
                            item={item!}
                            primary={index === 0}
                            backgroundUrl={backgroundUrl}
                            designersChoiceOnClick={designersChoiceOnClick}
                        />
                    ))}
            </Flex>
        </Flex>
    );
};

const containerCSS: any = ({ theme: { breakpoints } }: any) => ({
    padding: '1rem'
});

const textWrapperCSS: any = {
    alignItems: 'center'
};

const headingCSS: any = ({ theme: { baselineGrid, breakpoints } }: any) => ({
    width: '100%',
    textAlign: 'center',
    padding: `0 0 ${2 * baselineGrid}px 0`,
    [breakpoints.fromM]: {
        width: '66.6%'
    }
});

const subHeadingCSS: any = ({ theme: { breakpoints, baselineGrid } }: any) => ({
    width: '100%',
    textAlign: 'center',
    padding: `0 0 ${6 * baselineGrid}px 0`,
    [breakpoints.fromM]: {
        width: '66.6%'
    }
});

const itemsCSS: any = ({ theme: { breakpoints } }: any) => ({
    flexDirection: 'row',
    flex: '0 0 auto',
    [breakpoints.untilL]: {
        margin: '0 auto',
        width: '100%',
        maxWidth: '500px',
        flexWrap: 'wrap',
        justifyContent: 'space-between'
    },
    [breakpoints.fromL]: {
        width: '100%',
        justifyContent: 'center'
    }
});

export default TileGrid;
