import 'core-js';

import { createElement } from 'react';
import * as ReactDOM from 'react-dom';
import { Root } from './components/root';

import { VccUiProvider } from './lib';
import { RetailerContextContainer } from './containers/retailerContext';
import { ThemeProvider } from 'styled-components';
import { theme } from './layout';
import { ModelContextContainer } from './containers/modelContext';

const Env = ({ modelCode }: { modelCode: string }) => createElement(
    ModelContextContainer,
    { modelCode },
    createElement(
        RetailerContextContainer,
        {},
        createElement(
            VccUiProvider,
            {},
            createElement(
                ThemeProvider,
                { theme },
                createElement(Root)
            )
        )
    )
);

if (typeof document !== 'undefined') {
    document.addEventListener('DOMContentLoaded', () => {
        const elementId = 'fbi-app-designers_choice';
        const element = document.getElementById(elementId);

        if (!element) {
            console.error(`No element with id ${elementId} found on page`);
            return;
        }

        const modelCode = element?.dataset.modelCode;

        if (!modelCode) {
            console.error(`Root element missing [model-code] in dataset`);
            return;
        }

        ReactDOM.render(
            createElement(
                Env,
                { modelCode }
            ),
            element
        );
    });
}
