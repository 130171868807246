import React from 'react';
import { Flex, Text, Block, Button } from 'vcc-ui';
import { InventoryCarResult } from '../../@types/gql/buildAndPrice/InventoryCarResult';
import { fuelTypeI18n } from '../../helpers/i18n';
import { toSwePrice } from '../../helpers/toSweFormat';
import { weekFormat } from '../../helpers/weekFormat';

const TileGridItem: React.FC<{
    index: number;
    item: InventoryCarResult;
    backgroundUrl?: string;
    designersChoiceOnClick?: (commonOrderNumber: string) => void;
    primary: boolean;
}> = ({ index, item, backgroundUrl, designersChoiceOnClick, primary }) => {
    return (
        <Flex
            extend={({ theme: { baselineGrid, breakpoints } }: any) =>
                itemWrapperCSS({ baselineGrid, breakpoints, primary })
            }
        >
            <Flex extend={topWrapperCSS}>
                {/* !backgroundUrl ? null : (
                    <Img
                        objectFit="cover"
                        src={backgroundUrl}
                        extend={backgroundImageCSS}
                        alt={'vehicle background image'}
                    />
                ) */}
                <Block
                    style={{
                        ...backgroundImageCSS,
                        backgroundImage: `url(https://static.fbinhouse.se/2020-7/designers-choice-car-background.6c69b4f1.jpg)`
                    }}
                />
                <Flex extend={topInnerCSS}>
                    <Text
                        variant="hillary"
                        subStyle="emphasis"
                        extend={{ marginBottom: '.328em' }}
                        data-testid="designerschoice-item:heading"
                    >
                        {item.model?.name}{' '}
                        {fuelTypeI18n(item.fuelType?.code || '')}
                    </Text>
                    <Text
                        variant="bates"
                        extend={({ theme: { color } }: any) => ({
                            color: color.foreground.secondary
                        })}
                        data-testid="designerschoice-item:subheading"
                    >
                        {item.salesVersion?.name}
                    </Text>
                    <Block
                        extend={({ theme: { breakpoints } }: any) => ({
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                            backgroundImage: `url(${item.imageUrls?.exterior?.angle7})`,
                            paddingTop: '56.25%',
                            width: '100%',
                            [breakpoints.untilL]: {
                                paddingTop: primary ? '56.25%' : '80%',
                                backgroundSize: primary ? 'cover' : '180%',
                                backgroundPosition: 'bottom left'
                            }
                        })}
                    />
                </Flex>
            </Flex>

            <Flex
                extend={({ theme: { breakpoints } }: any) => ({
                    flexDirection: 'row',
                    alignItems: 'center',
                    [breakpoints.untilL]: {
                        flexDirection: primary ? 'row' : 'column'
                    }
                })}
            >
                <Block
                    extend={({ theme }: any) => priceCSS({ theme, primary })}
                >
                    <Text
                        variant="bates"
                        subStyle="emphasis"
                        data-testid="designerschoice-item:price"
                    >
                        {`Från ca ${toSwePrice(item.price?.msrp)}` || '$XXXX'}
                    </Text>
                    <Text
                        variant="bates"
                        extend={{
                            color: '#666'
                        }}
                    >
                        Uppskattad leveranstid<br />{weekFormat(item.numberOfWeeksToDelivery)}
                    </Text>
                </Block>
                <Button
                    intent="primary"
                    variant="outline"
                    onClick={() =>
                        designersChoiceOnClick &&
                        designersChoiceOnClick(item.commonOrderNumber!)
                    }
                >
                    Välj bil
                </Button>
            </Flex>
        </Flex>
    );
};

const itemWrapperCSS = ({ baselineGrid, breakpoints, primary }: any) => ({
    width: '100%',
    alignSelf: 'center',
    maxWidth: 360,
    paddingBottom: 6 * baselineGrid,

    [breakpoints.fromM]: {
        alignSelf: 'stretch',
        padding: 0
    },
    [breakpoints.untilL]: {
        maxWidth: primary ? '100%' : '48%',
        marginTop: primary ? 0 : '1rem'
    },
    [breakpoints.fromL]: {
        marginRight: 2 * baselineGrid,
        '&:last-child': {
            paddingBottom: 0,
            marginRight: 0
        }
    }
});

const topWrapperCSS = ({ theme: { color, baselineGrid } }: any) => ({
    position: 'relative',
    backgroundColor: color.background.secondary,
    marginBottom: '1rem'
});

const topInnerCSS = ({ theme: { baselineGrid } }: any) => ({
    zIndex: 2,
    alignItems: 'center',
    paddingTop: 6 * baselineGrid,
    overflow: 'hidden',
    textAlign: 'center'
});

const backgroundImageCSS = {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundSize: 'cover',
    backgroundPosition: 'bottom'
};

/* const descriptionCSS = ({ theme: { baselineSubGrid } }: any) => ({
    flex: '1 0 0',
    padding: `${5 * baselineSubGrid}px 0 ${3 * baselineSubGrid}px 0`
});

const noteCSS = ({ theme: { color, baselineGrid } }: any) => ({
    color: color.foreground.secondary,
    padding: `${baselineGrid}px 0 0 0`
}); */

const priceCSS = ({
    theme: { color, baselineSubGrid, breakpoints },
    primary
}: any) => ({
    width: '100%',
    textAlign: 'center',
    // color: color.brand.primary,
    [breakpoints.untilL]: {
        padding: primary
            ? 0
            : `${2 * baselineSubGrid}px 0 ${5 * baselineSubGrid}px 0`
    }
});

export default TileGridItem;
