import plug from '../assets/icons/plug.svg';
import station from '../assets/icons/station.svg';
import battery from '../assets/icons/battery.svg';

export const fuelIconMatcher = (fuelType: string | null | undefined) => {
    switch (fuelType) {
        case 'plugin':
            return [battery, plug, station];
        case 'electric':
            return [battery, plug];
        case 'diesel' || 'petrol':
            return [battery, station];
        default:
            return [];
    }
};
