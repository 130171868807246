import React, {
    useState,
    useMemo,
    useCallback,
    useRef,
    MutableRefObject,
    createContext,
    useEffect
} from 'react';
import * as ReactDOM from 'react-dom';
import { useSpring, animated } from 'react-spring/web.cjs';
import Overlay from '../overlay';
import TileGrid from '../tile-grid';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import { trackOverlayOpened } from '../../tracking';

export const ScrollTopProvider = createContext(0);

export const Root = () => {
    const [scrollTop, setScrollTop] = useState(-1);
    const [commonOrderNumber, setCommonOrderNumber] = useState<
        string | undefined
    >(undefined);
    const popoverElement = useRef<HTMLDivElement>();

    const volvoHeader = document.getElementById('primary-navigation-bar');
    const blockHide = 'top: 0px !important; margin-top: 0px !important;';
    const secondaryNavBar = document.getElementById('new-secondary-navigation')
        ?.children[0] as HTMLElement;
    const shiftIndex = 'z-index: 50';

    const handleOpen = useCallback(
        (commonOrderNumber: string) => {
            if (popoverElement.current) {
                disableBodyScroll(popoverElement.current);

                const htmlElement = document.getElementsByTagName('html')[0];

                const htmlStyle = htmlElement.getAttribute('style');

                htmlElement.setAttribute(
                    'style',
                    htmlStyle + ' overflow: hidden !important;'
                );
            }

            setCommonOrderNumber(commonOrderNumber);

            trackOverlayOpened();

            if (volvoHeader) volvoHeader.style.cssText += blockHide;
            if (secondaryNavBar) secondaryNavBar.style.cssText += shiftIndex;
        },
        [setCommonOrderNumber]
    );

    const handleClose = useCallback(() => {
        if (popoverElement.current) {
            enableBodyScroll(popoverElement.current);

            const htmlElement = document.getElementsByTagName('html')[0];

            const htmlStyle = htmlElement.getAttribute('style');

            htmlElement.setAttribute(
                'style',
                (htmlStyle || '').replace(/\s*overflow: hidden !important;/, '')
            );
        }

        setCommonOrderNumber(undefined);

        if (volvoHeader) {
            volvoHeader.style.cssText = volvoHeader.style.cssText.replace(
                blockHide,
                ''
            );
        }
        if (secondaryNavBar) {
            secondaryNavBar.style.cssText = secondaryNavBar.style.cssText.replace(
                shiftIndex,
                ''
            );
        }
    }, [setCommonOrderNumber]);

    const handleScroll = useCallback(() => {
        if (!popoverElement.current) {
            return;
        }

        setScrollTop(popoverElement.current.scrollTop);
    }, [popoverElement.current, setScrollTop]);

    useEffect(() => {
        if (popoverElement.current && scrollTop === -1) {
            popoverElement.current.addEventListener('scroll', handleScroll);
            setScrollTop(0);
        }
    }, [popoverElement.current, setScrollTop, scrollTop]);

    return (
        <ScrollTopProvider.Provider value={Math.max(scrollTop, 0)}>
            <TileGrid
                heading="Designade för att passa dig"
                subHeading="Våra bästa laddbara SUV:ar med förvalda utrustningspaket. Hitta din favorit."
                designersChoiceOnClick={handleOpen}
            />
            <Popover
                commonOrderNumber={commonOrderNumber}
                handleClose={handleClose}
                innerRef={popoverElement}
            />
        </ScrollTopProvider.Provider>
    );
};

interface IPopoverProps {
    commonOrderNumber?: string;
    handleClose: () => void;
    innerRef: MutableRefObject<HTMLDivElement | undefined>;
}

const Popover = ({
    commonOrderNumber,
    handleClose,
    innerRef
}: IPopoverProps) => {
    const container = useMemo(() => {
        const div = document.createElement('div');
        document.body.appendChild(div);

        return div;
    }, []);

    const openAnimation = useSpring({
        opacity: !commonOrderNumber ? 0 : 1
    });

    const handleScrollToTop = useCallback(() => {
        innerRef.current!.scrollTop = 0;
    }, [innerRef]);

    return ReactDOM.createPortal(
        <animated.div
            ref={innerRef as any}
            style={{
                ...openAnimation,
                position: 'fixed',
                top: 0,
                right: 0,
                left: 0,
                bottom: 0,
                background: '#fff',
                boxSizing: 'border-box',
                overflowX: 'hidden',
                overflowY: 'scroll',
                zIndex: 100,
                minHeight: '100%',
                pointerEvents: !commonOrderNumber ? 'none' : 'auto'
            }}
        >
            {commonOrderNumber ? (
                <Overlay
                    commonOrderNumber={commonOrderNumber}
                    handleClose={handleClose}
                    handleScrollToTop={handleScrollToTop}
                />
            ) : null}
        </animated.div>,
        container
    );
};
