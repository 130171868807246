import React from 'react';

export const Seperator: React.FC = () => (
    <hr
        style={{
            width: '100%',
            border: '1px solid #EBEBEB'
        }}
    />
);
