import { EMAIL_REGEX, PHONE_REGEX } from './constants';
import { FormRules } from './formTypes';
import { ICheckoutCustomerForm } from './types';

export const nonBlank = (message: string) => ({
  test: (v: string) => !!v,
  message
});

export const rules: FormRules<ICheckoutCustomerForm> = {
  firstname: [nonBlank('Ange ditt förnamn')],
  lastname: [nonBlank('Ange ditt efternamn')],
  address: [nonBlank('Ange din adress')],
  zipcode: [
    nonBlank('Ange ditt postnummer'),
    {
      test: v => /^\d{3}\s*\d{2}$/.test(v),
      message: 'Felaktigt format'
    }
  ],
  city: [nonBlank('Ange din ort')],
  email: [
    nonBlank('Ange din e-postadress'),
    {
      test: v => EMAIL_REGEX.test(v),
      message: 'Felaktigt format'
    }
  ],
  phone: [
    nonBlank('Ange ditt telefonnummer'),
    {
      test: v => PHONE_REGEX.test(v),
      message: 'Felaktigt format'
    }
  ],
  retailer: [nonBlank('Välj din Volvohandlare')]
};
