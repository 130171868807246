import * as React from 'react';
import { Block, Flex, Text } from 'vcc-ui';
import { theme } from '../../layout/theme';
import { InventoryItem_configuration_options } from '../../@types/gql/designersChoice/InventoryItem';

export interface IOptionDetailsProps {
    option: InventoryItem_configuration_options;
}

export const OptionDetails: React.FC<IOptionDetailsProps> = ({ option }) => (
    <Flex
        extend={{
            alignItems: 'center',
            [theme.breakpoint.LtSm]: {
                margin: `${theme.typeScale[8]} ${theme.typeScale[5]} ${theme.typeScale[8]}`
            }
        }}
    >
        <Block
            extend={{
                width: '100%',
                [theme.breakpoint.Sm]: {
                    width: '532px',
                    margin: `${theme.typeScale[13]} 0`
                },
                [theme.breakpoint.Md]: {
                    width: '604px',
                    '> *': {
                        marginBottom: theme.typeScale[8]
                    }
                }
            }}
        >
            <Flex extend={{ flexDirection: 'column' }}>
                <Block extend={{ marginBottom: 20 }}>
                    <img
                        src={option.imageUrl || undefined}
                        style={{ width: '100% '}}
                    />
                </Block>
                <Text variant="bates">
                    {option.description}
                </Text>
            </Flex>
        </Block>
    </Flex>
);
