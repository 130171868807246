import { useQuery } from '@apollo/react-hooks';
import * as gql from '../graphql/buildAndPrice';
import { SearchInventoryQuery } from '../@types/gql/buildAndPrice/SearchInventoryQuery';
import { buildAndPrice } from '../clients';
import { useContext } from 'react';
import { ModelContext } from '../containers/modelContext/context';
import { InventoryInput } from '../@types/gql/buildAndPrice/globalTypes';

const SALESVERSION_INSCRIPTION = ['8T', '13', '23'];
const SALESVERSION_R_DESIGN = ['RT', 'R5', 'R7'];

/* const ENGINE_T8_TWIN_ENGINE = ['BM'];
const ENGINE_RECHARGE_T5 = ['BB']; */

const createSearch = (search: InventoryInput): InventoryInput => ({
    commonSalesType: ['27A'],
    imageBackground: 'transparent',
    imageWidth: '1024',
    nsc: 'include',
    options: ['XRECST'],
    duplicates: 'exclude',
    ...search
});

export const useCars = () => {
    const modelCode = useContext(ModelContext);

    const searches: InventoryInput[] = [
        {
            model: [modelCode],
            salesVersion: SALESVERSION_INSCRIPTION,
            take: 2
        },
        {
            model: [modelCode],
            salesVersion: SALESVERSION_R_DESIGN,
            take: 1
        }
    ];

    const result = useQuery<SearchInventoryQuery>(gql.SEARCH_INVENTORY_QUERY, {
        client: buildAndPrice,
        variables: {
            market: 'se',
            maxCarsToFetch: 3,
            searches: searches.map(createSearch)
        }
    });

    return result;
};