import React, { useState, useRef } from 'react';
import { Flex, Text, Arrow } from 'vcc-ui';
import { useSpring, animated } from 'react-spring/web.cjs';

import { Seperator } from '../seperator';

export interface IProps {
    title: string;
}

export const DropDown: React.FC<IProps> = ({ title, children }) => {
    const [open, setOpen] = useState(false);
    const innerDiv = useRef<HTMLDivElement>(null);
    const openAnimation = useSpring({
        height: !open ? 0 : innerDiv.current ? innerDiv.current.clientHeight : 0
    });

    return (
        <Flex>
            <Flex
                extend={{
                    width: '100%',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    cursor: 'pointer',

                    '&:hover p': {
                        color: '#284e80'
                    }
                }}
                onClick={() => setOpen(!open)}
            >
                <Text variant="hillary" extend={{ marginBottom: '.64rem' }}>
                    {title}
                </Text>
                <Arrow
                    size={15}
                    color="#1C6BBA"
                    direction={open ? 'up' : 'down'}
                />
            </Flex>
            <animated.div style={{ ...openAnimation, overflow: 'hidden' }}>
                <Flex
                    extend={{
                        '> *': {
                            marginBottom: '.64rem'
                        }
                    }}
                    ref={innerDiv}
                >
                    {children}
                </Flex>
            </animated.div>
            <Seperator />
        </Flex>
    );
};
