import React from 'react';
import { Button, Text, Block, Inline, Flex, Link } from 'vcc-ui';
import { ModelTitle } from '../model-title';
import { Carousel } from '../carousel';
import { Option } from '../option';
import { SpecList } from '../spec-list';
import { SpecPair } from '../spec-pair';
import { SpecIcon } from '../spec-icon';
import { Seperator } from '../seperator';

import { fuelIconMatcher } from '../../helpers/fuelIconMatcher';

import gearbox from '../../assets/icons/gearbox.svg';
import wheels from '../../assets/icons/wheels.svg';
import seat from '../../assets/icons/seat.svg';
import { DropDown } from '../drop-down';
import { theme } from '../../layout/theme';
import { drivetrainI18n, fuelTypeI18n } from '../../helpers/i18n';
import { toSweNum, toSwePrice } from '../../helpers/toSweFormat';
import { IconArrowRight } from '../icon';
import { StickyCTA } from '../sticky-cta';
import {
    InventoryItem,
    InventoryItem_configuration_packages_options,
    InventoryItem_configuration_options,
    InventoryItem_configuration_packages/* ,
    InventoryItem_configuration_features */
} from '../../@types/gql/designersChoice/InventoryItem';
import { weekFormat } from '../../helpers/weekFormat';

export interface IProps {
    commonOrderNumber: string;
    handleClose: () => void;
    handleScrollToTop: () => void;
}

export interface IPDPProps {
    item: InventoryItem;
    handleCheckout: () => void;
    handleOption: (optionCode: string | null) => void;
    handleFeatures: () => void;
}

const HIDDEN_OPTIONS = ['000854', 'A00463', 'XRECST'];

const removeHiddenOptions = (
    opt:
        | InventoryItem_configuration_options
        | InventoryItem_configuration_packages_options
) => HIDDEN_OPTIONS.indexOf(opt!.code!) === -1;

const removePackageOptions = (
    packages: InventoryItem_configuration_packages[]
) => {
    const hidden = packages.reduce((hidden, pkg) => ([ ...hidden, ...pkg.options.map((opt) => opt.code) ]), []);

    return (
        opt:
            | InventoryItem_configuration_options
            | InventoryItem_configuration_packages_options
    ) => hidden.indexOf(opt.code) === -1;
};

const removePackageUsps = (
    packages: InventoryItem_configuration_packages[]
) => {
    const hidden = packages.reduce((hidden, pkg) => ([ ...hidden, ...pkg.options.map((opt) => opt.name) ]), []);

    return (
        usp: string
    ) => hidden.indexOf(usp) === -1;
};

export const PDP: React.FC<IPDPProps> = ({
    item,
    handleCheckout,
    handleOption,
    handleFeatures
}) => {
    const options = item.configuration?.options
        .filter(removePackageOptions(item.configuration.packages))
        .filter(removeHiddenOptions);

    return (
        <>
            <Flex
                extend={{
                    width: '100%',
                    maxWidth: '1200px',
                    margin: '0 auto',
                    [theme.breakpoint.Md]: { flexDirection: 'row' }
                }}
            >
                <Block
                    extend={{
                        [theme.breakpoint.Md]: {
                            position: 'relative',
                            width: '50%',
                            backgroundColor: '#FAFAFA'
                        }
                    }}
                >
                    <Carousel>
                        {
                            [
                                ...Object.keys(item.imageUrls?.exterior)
                                    .slice(0, 8)
                                    .map((key) => item.imageUrls!.exterior[key]),
                                ...Object.keys(item.imageUrls.interior)
                                    .slice(0, 8)
                                    .map((key) => item.imageUrls.interior[key])
                            ].map((src, idx) => (
                                <img
                                    src={src}
                                    key={idx}
                                />
                            ))
                        }
                    </Carousel>
                </Block>
                <Flex
                    extend={{
                        alignItems: 'center',

                        [theme.breakpoint.LtSm]: {
                            margin: `0 ${theme.typeScale[5]} ${theme.typeScale[8]}`
                        },

                        [theme.breakpoint.Md]: {
                            width: '50%',
                            margin: `${theme.typeScale[11]} 0`
                        }
                    }}
                >
                    <Block
                        extend={{
                            [theme.breakpoint.Sm]: {
                                maxWidth: '532px'
                            },
                            [theme.breakpoint.Md]: {
                                margin: `0 ${theme.typeScale[11]}`
                            },
                            '> * + *': {
                                marginTop: theme.typeScale[8]
                            }
                        }}
                    >
                        <ModelTitle
                            mainTitle={`${item.configuration?.model.name} ${item.configuration?.engine.name}`}
                            subTitle={`${item.configuration?.salesVersion.name}`}
                            price={`Från ca ${toSwePrice(item.price.msrp)}`}
                        />
                        <SpecList marginBottom={theme.typeScale[9]}>
                            <SpecPair>
                                <SpecIcon
                                    icons={fuelIconMatcher(
                                        item.configuration?.fuelType.code
                                    )}
                                    title={fuelTypeI18n(
                                        item.configuration?.fuelType.code || ''
                                    )}
                                ></SpecIcon>
                                <SpecIcon
                                    icons={[gearbox]}
                                    title={item.configuration?.gearbox.name}
                                ></SpecIcon>
                            </SpecPair>
                            <SpecPair>
                                <SpecIcon
                                    icons={[wheels]}
                                    title={drivetrainI18n(
                                        item.configuration?.drivetrain.code || ''
                                    )}
                                ></SpecIcon>
                                <SpecIcon
                                    icons={[seat]}
                                    title={`${
                                        item.configuration?.numberOfSeats || '5'
                                    } säten`}
                                ></SpecIcon>
                            </SpecPair>
                        </SpecList>
                        <Seperator />
                        <SpecList>
                            <SpecPair>
                                <Text subStyle="emphasis">
                                    Bränsleförbrukning (WLTP)
                                </Text>
                                <Text>
                                    {`${toSweNum(
                                        item.configuration?.wltp
                                            .weightedCombinedFuelConsumption?.value
                                    )} ${
                                        item.configuration?.wltp
                                            .weightedCombinedFuelConsumption?.unit
                                    }`}
                                </Text>
                            </SpecPair>
                            <SpecPair>
                                <Text subStyle="emphasis">CO2-utsläpp (WLTP)</Text>
                                <Text>
                                    {`${item.configuration?.wltp.weightedCombinedCO2?.value} ${item.configuration?.wltp.weightedCombinedCO2?.unit}`}
                                </Text>
                            </SpecPair>
                            <SpecPair>
                                <Text subStyle="emphasis">
                                    Räckvidd på el (WLTP)
                                </Text>
                                <Text>
                                    Upp till{' '}
                                    {`${item.configuration?.wltp.electricRange?.value} ${item.configuration?.wltp.electricRange?.unit}`}
                                </Text>
                            </SpecPair>
                            <SpecPair>
                                <Text subStyle="emphasis">Exteriörfärg</Text>
                                <Text>{item.configuration?.color.name}</Text>
                            </SpecPair>
                            <SpecPair>
                                <Text subStyle="emphasis">Klädsel</Text>
                                <Text>{item.configuration?.upholstery.name}</Text>
                            </SpecPair>
                        </SpecList>
                        <SpecList title="Pris">
                            <SpecPair rightAlign>
                                <Text variant="hillary">Totalpris *</Text>
                                <Text variant="hillary" subStyle="emphasis">
                                    {`Från ca ${toSwePrice(item.price.msrp)}`}
                                </Text>
                            </SpecPair>
                            <Seperator />
                            <SpecPair rightAlign>
                                <Text>Uppskattad leveranstid **</Text>
                                <Text subStyle="emphasis">
                                    {weekFormat(item.numberOfWeeksToDelivery)}
                                </Text>
                            </SpecPair>
                            <Text variant="bates" extend={{ color: '#707070' }}>
                                * Rekommenderat försäljningspris. ** Leveranstiden
                                är beräknad från beställning fram till att bilen
                                levereras till din Volvohandlare.
                            </Text>
                        </SpecList>
                        <Block
                            extend={{
                                [theme.breakpoint.LtMd]: {
                                    display: 'none'
                                }
                            }}
                        >
                            <Button intent="primary" onClick={handleCheckout}>
                                Gå vidare
                                <Inline
                                    extend={{
                                        marginLeft: 10
                                    }}
                                >
                                    <IconArrowRight />
                                </Inline>
                            </Button>
                        </Block>
                    </Block>
                </Flex>
            </Flex>
            <Flex
                extend={{
                    alignItems: 'center',
                    [theme.breakpoint.LtSm]: {
                        margin: `${theme.typeScale[8]} ${theme.typeScale[5]} ${theme.typeScale[8]}`
                    }
                }}
            >
                <Block
                    extend={{
                        width: '100%',
                        '> *': {
                            marginBottom: theme.typeScale[8]
                        },
                        [theme.breakpoint.Sm]: {
                            width: '532px',
                            margin: `${theme.typeScale[13]} 0`
                        },
                        [theme.breakpoint.Md]: {
                            width: '604px',
                            '> *': {
                                marginBottom: theme.typeScale[8]
                            }
                        }
                    }}
                >
                    <Text
                        variant="ootah"
                        subStyle="emphasis"
                    >
                        Utrustning för {item.configuration?.model.name}
                    </Text>

                    <SpecList title="Utrustningspaket">
                        {item.configuration?.packages.map((pack) => (
                            <DropDown title={pack!.name!} key={pack!.code!}>
                                <Text variant="bates" extend={{ color: '#707070' }}>
                                    Paketet innehåller:
                                </Text>
                                {
                                    pack?.options
                                        ?.filter(removeHiddenOptions)
                                        .map((opt) => (
                                            <Option
                                                key={opt.code}
                                                option={opt}
                                                onOpen={() => handleOption(opt.code)}
                                            />
                                        ))
                                }
                            </DropDown>
                        ))}
                    </SpecList>
                    {
                        options && options.length > 0
                        ? (
                            <SpecList title="Tillval">
                                {
                                    options
                                        .map((option) => (
                                            <Option
                                                key={option.code}
                                                option={option}
                                                onOpen={() => handleOption(option.code)}
                                            />
                                        ))
                                }
                            </SpecList>
                        )
                        : null
                    }
                    <SpecList title="Utvalda detaljer">
                        {
                            item.configuration?.usps
                                .filter(removePackageUsps(item.configuration.packages))
                                .map((feature) => (
                                    <Text key={feature}>{feature}</Text>
                                ))
                        }
                        <Inline
                            extend={{
                                '> button:hover': {
                                    backgroundColor: '#fff !important',
                                    borderColor: '#fff !important',
                                    color: 'rgb(20, 20, 20) !important'
                                }
                            }}
                        >
                            <Link arrow="right" onClick={handleFeatures}>
                                Visa alla detaljer
                            </Link>
                        </Inline>
                    </SpecList>
                </Block>
            </Flex>
            <Flex
                extend={{
                    width: '100%',
                    backgroundColor: '#FAFAFA',
                    alignItems: 'center'
                }}
            >
                <Block
                    extend={{
                        // width: '100%',
                        padding: '1.953rem 0',

                        [theme.breakpoint.LtSm]: {
                            margin: `0 ${theme.typeScale[5]} ${theme.typeScale[8]}`
                        },
                        [theme.breakpoint.Sm]: {
                            maxWidth: '532px',
                            margin: `${theme.typeScale[13]} 0`
                        },
                        [theme.breakpoint.Md]: {
                            maxWidth: '604px',
                            margin: '5.96em 0'
                        }
                    }}
                >
                    <SpecList title="Förbrukning och CO2-utsläpp">
                        <Text subStyle="emphasis">NEDC</Text>
                        <SpecPair rightAlign>
                            <Text>CO2-utsläpp</Text>
                            <Text subStyle="emphasis">{`${item.configuration?.nedc.weightedCombinedCO2?.value} ${item.configuration?.nedc.weightedCombinedCO2?.unit}`}</Text>
                        </SpecPair>
                        <SpecPair rightAlign>
                            <Text>Bränsleförbrukning</Text>
                            <Text subStyle="emphasis">{`${toSweNum(
                                item.configuration?.nedc
                                    .weightedCombinedFuelConsumption?.value
                            )} ${
                                item.configuration?.nedc
                                    .weightedCombinedFuelConsumption?.unit
                            }`}</Text>
                        </SpecPair>
                        {item.configuration?.nedc.electricRange && (
                            <SpecPair rightAlign>
                                <Text>Räckvidd på el</Text>
                                <Text subStyle="emphasis">{`${toSweNum(
                                    item.configuration?.nedc.electricRange.value
                                )} ${
                                    item.configuration?.nedc.electricRange.unit
                                }`}</Text>
                            </SpecPair>
                        )}
                        {item.configuration?.nedc.electricEnergyConsumption && (
                            <SpecPair rightAlign>
                                <Text>Strömförbrukning</Text>
                                <Text subStyle="emphasis">{`${toSweNum(
                                    item.configuration?.nedc.electricEnergyConsumption.value
                                )} ${
                                    item.configuration?.nedc.electricEnergyConsumption.unit
                                }`}</Text>
                            </SpecPair>
                        )}
                        <Text subStyle="emphasis">WLTP</Text>
                        <SpecPair rightAlign>
                            <Text>CO2-utsläpp</Text>
                            <Text subStyle="emphasis">{`${item.configuration?.wltp.weightedCombinedCO2?.value} ${item.configuration?.wltp.weightedCombinedCO2?.unit}`}</Text>
                        </SpecPair>
                        <SpecPair rightAlign>
                            <Text>Bränsleförbrukning</Text>
                            <Text subStyle="emphasis">{`${toSweNum(
                                item.configuration?.wltp
                                    .weightedCombinedFuelConsumption?.value
                            )} ${
                                item.configuration?.wltp
                                    .weightedCombinedFuelConsumption?.unit
                            }`}</Text>
                        </SpecPair>
                        {item.configuration?.wltp.electricRange && (
                            <SpecPair rightAlign>
                                <Text>Räckvidd på el</Text>
                                <Text subStyle="emphasis">{`${toSweNum(
                                    item.configuration?.wltp.electricRange.value
                                )} ${
                                    item.configuration?.wltp.electricRange.unit
                                }`}</Text>
                            </SpecPair>
                        )}
                        {item.configuration?.wltp.electricEnergyConsumption && (
                            <SpecPair rightAlign>
                                <Text>Strömförbrukning</Text>
                                <Text subStyle="emphasis">{`${toSweNum(
                                    item.configuration?.wltp.electricEnergyConsumption.value
                                )} ${
                                    item.configuration?.wltp.electricEnergyConsumption.unit
                                }`}</Text>
                            </SpecPair>
                        )}
                        <Text variant="bates" extend={{ color: '#707070' }}>
                            WLTP-standarden (Worldwide Harmonized Light-Duty
                            Vehicles Test Procedure) representerar de genomsnittliga
                            körförhållandena för vardaglig körning. I jämförelse med
                            den tidigare standarden (NEDC) tar WLTP hänsyn till mer
                            varierande trafiksituationer och hastigheter men även
                            utrustning och viktklasser.
                        </Text>
                    </SpecList>
                </Block>
            </Flex>
            <StickyCTA handleCheckout={handleCheckout} />
        </>
    );
};
