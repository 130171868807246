import React, { useState, useRef, useEffect } from 'react';
import { useTransition, animated } from 'react-spring/web.cjs';
import { Arrow, Block, Flex } from 'vcc-ui';
import { theme } from '../../layout/theme';

export interface IProps {
    children: JSX.Element | JSX.Element[];
}

export const Carousel: React.FC<IProps> = ({ children }) => {
    if (!children) return null;
    const [image, setImage] = useState<number>(0);
    const forward = useRef<boolean>(true);
    const imageCount = React.Children.count(children);

    const mod = (n: number, m: number) => ((n % m) + m) % m;

    const transition = useTransition(image, null, {
        initial: {
            transform: 'translateX(0)',
            opacity: 1
        },
        from: {
            transform: `translateX(${50 * (forward.current ? 1 : -1)}%)`,
            opacity: 0
        },
        enter: {
            transform: 'translateX(0)',
            opacity: 1
        },
        leave: {
            transform: `translateX(${50 * (forward.current ? -1 : 1)}%)`,
            opacity: 0
        }
    });

    useEffect(() => {
        // Preloads images in carousel
        React.Children.forEach(children, (child) => {
            const preload = new Image();
            if ('props' in child) {
                preload.src = child.props.src;
            }
        });
    }, [children]);

    return (
        <Block
            extend={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                margin: '0 0 1.953rem',

                [theme.breakpoint.Md]: {
                    paddingTop: theme.typeScale[10],
                    position: 'sticky',
                    top: 0
                }
            }}
        >
            <Block
                extend={{
                    width: '100%',
                    height: 0,
                    paddingTop: '75%',
                    position: 'relative',
                    overflow: 'hidden',
                    backgroundColor: '#FAFAFA',

                    '> * + *': {
                        position: 'absolute',
                        cursor: 'pointer',
                        top: '50%',
                        transform: 'translateY(-50%)',

                        '&:hover svg': {
                            fill: '#141414'
                        }
                    }
                }}
            >
                {transition.map(({ item, props, key }) => (
                    <animated.div
                        style={{
                            ...props,
                            position: 'absolute',
                            top: 0,
                            width: '100%',
                            height: '100%'
                        }}
                        key={key}
                    >
                        <Block
                            extend={{
                                width: '100%',
                                height: '100%',

                                '> img': {
                                    width: '100%',
                                    height: '100%',
                                    objectFit: 'contain',
                                    backgroundFit: 'cover'
                                }
                            }}
                        >
                            {children[item]}
                        </Block>
                    </animated.div>
                ))}
                <Block
                    extend={{ left: 0 }}
                    onClick={() => {
                        forward.current = false;
                        setImage(mod(image - 1, imageCount));
                    }}
                >
                    <Arrow direction="left" size={45} color="#D5D5D5" />
                </Block>
                <Block
                    extend={{
                        left: 'calc(100% - 45px)'
                    }}
                    onClick={() => {
                        forward.current = true;
                        setImage(mod(image + 1, imageCount));
                    }}
                >
                    <Arrow direction="right" size={45} color="#D5D5D5" />
                </Block>
            </Block>
            <Flex
                extend={{
                    flexDirection: 'row',
                    marginTop: '.64rem',
                    justifyContent: 'center'
                }}
            >
                {Array(imageCount)
                    .fill(0)
                    .map((el, idx) => (
                        <Block
                            key={'nav-circle-' + idx}
                            extend={{
                                padding: '.328rem',
                                cursor: 'pointer',
                                '&:hover > *': {
                                    backgroundColor: '#141414'
                                }
                            }}
                            onClick={() => {
                                forward.current = idx > image;
                                setImage(idx);
                            }}
                        >
                            <Block
                                extend={{
                                    width: '8px',
                                    height: '8px',
                                    backgroundColor:
                                        idx === image ? '#141414' : '#D5D5D5',
                                    borderRadius: '50%'
                                }}
                            />
                        </Block>
                    ))}
            </Flex>
        </Block>
    );
};
