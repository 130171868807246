import { INVENTORY_CAR_RESULT_FRAGMENT } from './inventoryCarResult.graphql';
import gql from 'graphql-tag';

export const SEARCH_INVENTORY_QUERY = gql`
    query SearchInventoryQuery(
        $market: String!
        $maxCarsToFetch: Int
        $searches: [InventoryInput!]!
    ) {
        searchInventory(
            market: $market
            maxCarsToFetch: $maxCarsToFetch
            searches: $searches
        ) {
            cars {
                ...InventoryCarResult
            }
            metadata {
                totalHits
                returnedHits
                aggregations {
                    name
                    values {
                        value
                        count
                        name
                        __typename
                    }
                    __typename
                }
                __typename
            }
            __typename
        }
    }
    ${INVENTORY_CAR_RESULT_FRAGMENT}
`;
