import gql from 'graphql-tag';

export const INVENTORY_CAR_FRAGMENT = gql`
    fragment InventoryCar on InventoryCar {
        commonOrderNumber
        imageUrls {
            exterior
            interior
            __typename
        }
        model {
            name
            __typename
        }
        engine {
            name
            __typename
        }
        salesVersion {
            name
            __typename
        }
        gearbox {
            code
            name
            __typename
        }
        numberOfSeats
        drivetrain {
            code
            name
            __typename
        }
        fuelType {
            code
            name
            __typename
        }
        color {
            name
            __typename
        }
        upholstery {
            name
            __typename
        }
        options {
            code
            name
            __typename
        }
        packages {
            code
            name
            options{
                code
                name
                __typename
            }
            __typename
        }
        nedc {
            weightedCombinedCO2 {
                name
                value
                unit
                __typename
            }
            weightedCombinedFuelConsumption {
                name
                value
                unit
                __typename
            }
            electricRange {
                name
                value
                unit
                __typename
            }
            __typename
        }
        wltp {
            weightedCombinedCO2 {
                name
                value
                unit
                __typename
            }
            weightedCombinedFuelConsumption {
                name
                value
                unit
                __typename
            }
            electricRange {
                name
                value
                unit
                __typename
            }
            __typename
        }
        numberOfWeeksToDelivery
        numberOfWeeksToCustomerDelivery
        price {
            msrp
            __typename
        }
        standardFeatures {
            code
            name
            __typename
        }
        __typename
    }
`;