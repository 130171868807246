import { useQuery } from '@apollo/react-hooks';
import * as gql from '../graphql/designersChoice';
import { designersChoice } from '../clients'
import { InventoryItemQuery } from '../@types/gql/designersChoice/InventoryItemQuery';

export const useCarByCommonOrderNumber = (commonOrderNumber: string) => {
    return useQuery<InventoryItemQuery>(gql.INVENTORY_ITEM, {
        client: designersChoice,
        variables: {
            commonOrderNumber
        }
    });
};