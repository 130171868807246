import React from 'react';
import { Flex } from 'vcc-ui';

export interface IProps {
    rightAlign?: boolean;
}

export const SpecPair: React.FC<IProps> = ({
    children,
    rightAlign = false
}) => (
    <Flex
        extend={{
            width: '100%',
            flexDirection: 'row',
            justifyContent: 'space-between',

            '> *:nth-child(2n)': {
                textAlign: rightAlign ? 'right' : 'left'
            },

            '> *': {
                width: rightAlign ? 'auto' : '50%'
            }
        }}
    >
        {children}
    </Flex>
);
