import * as React from 'react';
import { StyleProvider, styleRenderer, ThemeProvider } from 'vcc-ui';
import volvo from 'vcc-ui/lib/themes/volvo';

const renderer = styleRenderer({
    selectorPrefix: 'fbinhouse__vcc-ui-sandbox-'
});

const pathPrefix = 'https://pkgs.gvcs.fbinhouse.se/vcc-ui/1.2.1/assets';

export const VccUiProvider: React.FC = (props) => (
    <StyleProvider renderer={renderer}>
        <ThemeProvider
            theme={{
                ...volvo,
                fontsPath: `${pathPrefix}/fonts/`,
                logoImagesPath: `${pathPrefix}/images/`
            }}
        >
            <>
                {props.children}
            </>
        </ThemeProvider>
    </StyleProvider>
);
