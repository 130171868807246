import React, { useCallback, useState, useMemo } from 'react';
import { animated, useTransition } from 'react-spring/web.cjs';
import { useCarByCommonOrderNumber } from '../../hooks';
import { Checkout } from '../checkout';
import { OverlayHeader } from '../overlay-header';
import { PDP } from '../pdp';
import { Loader } from '../loader';
import { trackFormOpened } from '../../tracking';
import { Features } from '../features';
import { OptionDetails } from '../optionDetails';

export interface IProps {
    commonOrderNumber: string;
    handleClose: () => void;
    handleScrollToTop: () => void;
}

const Overlay: React.FC<IProps> = ({
    commonOrderNumber,
    handleClose,
    handleScrollToTop
}) => {
    const { data, loading } = useCarByCommonOrderNumber(commonOrderNumber);

    const [currentView, setCurrentView] = useState<'pdp' | 'checkout' | 'features' | 'option'>('pdp');
    const [optionCode, setOptionCode] = useState<string | null>(null);

    const transitions = useTransition(currentView, null, {
        from: {
            position: 'absolute',
            width: '100%',
            height: '100%',
            background: '#fff',
            opacity: 0
        },
        enter: { opacity: 1 },
        leave: { opacity: 0 }
    });

    const handleCheckout = useCallback(() => {
        handleScrollToTop();
        setCurrentView('checkout');
        trackFormOpened();
    }, [setCurrentView]);

    const handleOption = useCallback((optionCode: string | null) => {
        handleScrollToTop();
        setOptionCode(optionCode);
        setCurrentView('option');
    }, [setCurrentView, setOptionCode]);

    const handleFeatures = useCallback(() => {
        handleScrollToTop();
        setCurrentView('features');
    }, [setCurrentView]);

    const handleBack = useCallback(() => {
        handleScrollToTop();
        setOptionCode(null);
        setCurrentView('pdp');
    }, [setCurrentView]);

    const option = useMemo(
        () => optionCode
            ? data?.inventoryItem?.configuration?.options.find((opt) => opt.code === optionCode)
            : undefined,
        [data, optionCode]
    );

    let content: React.ReactNode = null;

    if (data?.inventoryItem) {
        content = transitions.map(({ item, props }) => {
            switch (item) {
                case 'checkout':
                    return (
                        <animated.div style={props} key="checkout">
                            <Checkout commonOrderNumber={commonOrderNumber} />
                        </animated.div>
                    );
                case 'pdp':
                    return (
                        <animated.div style={props} key="pdp">
                            <PDP
                                item={data.inventoryItem!}
                                handleCheckout={handleCheckout}
                                handleFeatures={handleFeatures}
                                handleOption={handleOption}
                            />
                        </animated.div>
                    );
                case 'features':
                    return (
                        <animated.div style={props} key="features">
                            <Features features={data.inventoryItem!.configuration?.features || []} />
                        </animated.div>
                    );
                case 'option':
                    return (
                        <animated.div style={props} key="option">
                            {
                                option
                                ? (
                                    <OptionDetails option={option} />
                                )
                                : null
                            }
                        </animated.div>
                    );
            }
        });
    } else if (loading) {
        content = <Loader />;
    }

    const currentModel = `${data?.inventoryItem?.configuration?.model?.name || ''} ${
        data?.inventoryItem?.configuration?.engine?.name || ''
    }`;

    let headerLabel: string = '';

    if (!loading) {
        switch (currentView) {
            case 'checkout':
                headerLabel = `Beställ din ${currentModel}`;
                break;
            case 'features':
                headerLabel = `Detaljer`;
                break;
            case 'option':
                headerLabel = option ? option.name : '';
                break;
            case 'pdp':
            default:
                headerLabel = `Din ${currentModel}`;
                break;
        }
    }

    const hasBackButton = currentView !== 'pdp';

    return (
        <>
            <OverlayHeader
                currentModel={headerLabel}
                backFn={hasBackButton ? handleBack : undefined}
                exitFn={!hasBackButton ? handleClose : undefined}
            />
            {content}
        </>
    );
};

export default Overlay;
