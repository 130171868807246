import gql from 'graphql-tag';

export const RETAILER_FRAGMENT = gql`
    fragment Retailer on RETAILER {
        id
        city
        name
        email
        branchcode
        latitude
        longitude
        logo
        created_at
        updated_at
    }
`;
