import * as React from 'react';
import { Text, Block, Flex } from 'vcc-ui';
import { theme } from '../../layout/theme';
import { InventoryItem_configuration_features } from '../../@types/gql/designersChoice/InventoryItem';

export interface IFeaturesProps {
    features: InventoryItem_configuration_features[];
}

export const Features: React.FC<IFeaturesProps> = ({ features }) => (
    <Flex
        extend={{
            alignItems: 'center',
            [theme.breakpoint.LtSm]: {
                margin: `${theme.typeScale[8]} ${theme.typeScale[5]} ${theme.typeScale[8]}`
            }
        }}
    >
        <Block
            extend={{
                width: '100%',
                [theme.breakpoint.Sm]: {
                    width: '532px',
                    margin: `${theme.typeScale[13]} 0`
                },
                [theme.breakpoint.Md]: {
                    width: '604px',
                    '> *': {
                        marginBottom: theme.typeScale[8]
                    }
                }
            }}
        >
            {features.map((feature) => (
                <Text key={feature.code}>{feature.name}</Text>
            ))}
        </Block>
    </Flex>
);
