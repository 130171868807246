/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum CHECKOUT_TYPE {
  CARE_BY_VOLVO = "CARE_BY_VOLVO",
  CAR_LOAN = "CAR_LOAN",
  COMPANY_CASH_PAYMENT = "COMPANY_CASH_PAYMENT",
  DESIGNERS_CHOICE = "DESIGNERS_CHOICE",
  FINANCIAL_LEASE = "FINANCIAL_LEASE",
  OPERATIONAL_LEASE = "OPERATIONAL_LEASE",
  PRIVATE_CASH_PAYMENT = "PRIVATE_CASH_PAYMENT",
  PRIVATE_LEASE = "PRIVATE_LEASE",
}

export interface CHECKOUT_INPUT {
  checkoutType: CHECKOUT_TYPE;
  correlationId: string;
  originUrl: string;
  loanParameters: LOAN_PARAMETERS;
}

export interface CUSTOMER_INPUT {
  email: string;
  firstname: string;
  lastname: string;
  phone: string;
  address: string;
  zipcode: string;
  city: string;
  country: string;
  newsletterOptIn: boolean;
  contactTime?: string | null;
  contactMedia?: string | null;
  messageToRetailer?: string | null;
  externalCustomerId?: string | null;
}

export interface LOAN_PARAMETERS {
  interest: number;
  deposit: number;
  duration: number;
  monthlyCost: number;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
