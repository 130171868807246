import React from 'react';
import { Flex, Block, Text, Arrow } from 'vcc-ui';
import closeIcon from '../../assets/icons/close.svg';

export interface IProps {
    currentModel?: string | null;
    backFn?(): void;
    exitFn?(): void;
}

export const OverlayHeader: React.FC<IProps> = ({
    currentModel,
    backFn,
    exitFn
}) => (
    <>
        <Block extend={{ height: '3rem' }} />
        <Block
            extend={{
                width: '100%',
                position: 'sticky',
                top: '70px',
                zIndex: '100',
                backgroundColor: '#fff',
                borderBottom: '2px solid #EFECE9',
                marginBottom: '0'
            }}
        >
            <Flex
                extend={{
                    width: '100%',
                    height: '3rem',
                    maxWidth: '1200px',
                    margin: '0 auto',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'center',
                    position: 'relative'
                }}
            >
                {
                    exitFn
                    ? (
                        <Block
                            extend={{
                                position: 'absolute',
                                right: '0',
                                paddingRight: '14px',
                                cursor: 'pointer',

                                '> img': {
                                    width: '24px',
                                    transition: 'transform 200ms ease-in-out'
                                },

                                '&:hover > img': {
                                    transform: 'scale(1.2)',
                                    transition: 'transform 200ms ease-in-out'
                                }
                            }}
                            onClick={exitFn}
                        >
                            <img src={closeIcon} alt="close" />
                        </Block>
                    )
                    : null
                }
                {currentModel && (
                    <Text variant="columbus" subStyle="emphasis">
                        {currentModel}
                    </Text>
                )}
                {backFn ? (
                    <Block
                        extend={{
                            position: 'absolute',
                            left: '0',
                            paddingLeft: '14px',
                            cursor: 'pointer',
                            transition: 'transform 200ms ease-in-out',

                            '> img': {
                                width: '24px'
                            },

                            '&:hover': {
                                transform: 'translateX(-4px)',
                                transition: 'transform 200ms ease-in-out'
                            }
                        }}
                        onClick={backFn}
                    >
                        <Arrow direction="left" size={18} />
                    </Block>
                ) : null}
            </Flex>
        </Block>
    </>
);
