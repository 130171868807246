import styled, { css } from 'styled-components';
import { size } from '../../layout';

export default styled.div<{
    large?: boolean;
    small?: boolean;
    tiny?: boolean;
}>`
  & + & {
    margin-top: ${size(2)};

    ${props => props.theme.breakpoint.LtSm} {
      margin-top: ${size(1.5)};
    }
  }

  ${({ large }) =>
        large &&
        css`
      & + & {
        margin-top: ${size(3)};

        ${props => props.theme.breakpoint.LtSm} {
          margin-top: ${size(1.5)};
        }
      }
    `}

  ${({ small }) =>
        small &&
        css`
      & + & {
        margin-top: ${size(1)};
      }
    `}

  ${({ tiny }) =>
        tiny &&
        css`
      & + & {
        margin-top: ${size(0.5)};
      }
    `}
`;
