import * as React from 'react';
import { Spinner } from 'vcc-ui';
import styled from 'styled-components';

const WrapperDiv = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const Loader = () => (
    <WrapperDiv>
        <Spinner size={60} />
    </WrapperDiv>
);
