import gql from 'graphql-tag';

export const INVENTORY_ITEM = gql`
    query InventoryItemQuery(
        $commonOrderNumber: String!
    ) {
        inventoryItem(
            commonOrderNumber: $commonOrderNumber
        ) {
            ...InventoryItem
        }
    }

    fragment InventoryItem on InventoryItem {
        commonOrderNumber
        imageUrls {
            exterior
            interior
        }
        numberOfWeeksToDelivery
        numberOfWeeksToCustomerDelivery
        price {
            preTax
            preTaxAmount
            tax
            taxAmount
            msrp
            msrpAmount
        }
        configuration {
            model {
                code
                name
            }
            engine {
                code
                name
            }
            salesVersion {
                code
                name
            }
            gearbox {
                code
                name
            }
            drivetrain {
                code
                name
            }
            fuelType {
                code
                name
            }
            color {
                code
                name
            }
            upholstery {
                code
                name
            }
            options {
                code
                name
                description
                imageUrl
            }
            packages {
                code
                name
                description
                imageUrl
                options {
                    code
                    name
                }
            }
            nedc{
                weightedCombinedCO2 {
                    name
                    value
                    unit
                }
                weightedCombinedFuelConsumption {
                    name
                    value
                    unit
                }
                electricRange {
                    name
                    value
                    unit
                }
                electricEnergyConsumption {
                    name
                    value
                    unit
                }
            }
            wltp{
                weightedCombinedCO2{
                    name
                    value
                    unit
                }
                weightedCombinedFuelConsumption{
                    name
                    value
                    unit
                }
                electricRange{
                    name
                    value
                    unit
                }
                electricEnergyConsumption {
                    name
                    value
                    unit
                }
            }
            features {
                code
                name
            }
            usps
            numberOfSeats
        }
    }
`;