import * as React from 'react';
import Button, { ButtonContent } from '../button';
import Disclaimer from '../disclaimer';
import { H2 } from '../heading';
import { IconArrowRight } from '../icon';
import Repeat from '../repeat';
import TextInput from '../text-input';
import SelectInput from '../select-input';
import { useCheckoutForm } from './useCheckoutForm';
import { useRetailers } from '../../hooks/useRetailers';
import { Block, Flex } from 'vcc-ui';
import { theme } from '../../layout';
import { Loader } from '../loader';

export interface IProps {
    commonOrderNumber: string;
}

export const CheckoutError = () => (
    <div>
        <div>Ett oväntat fel har inträffat</div>
        <div>Var god försök igen senare</div>
    </div>
);

export const CheckoutSuccess = () => (
    <>
        <H2>Tack!</H2>
        <Repeat>
            Du kommer att bli kontaktad inom kort. Tillsammans går vi igenom din
            intresseanmälan och ser till att den uppfyller dina behov.
        </Repeat>
    </>
);

export const Checkout: React.SFC<IProps> = ({ commonOrderNumber }) => {
    const form = useCheckoutForm(commonOrderNumber);
    const [retailers] = useRetailers();

    const {
        values,
        success,
        error,
        errors,
        isSubmitting,
        loading,
        setFormValue,
        submitForm,
        handleFocus
    } = form;

    let content: React.ReactNode;

    if (success) {
        content = <CheckoutSuccess />;
    } else if (error) {
        content = <CheckoutError />;
    } else if (loading) {
        content = <Loader />;
    } else {
        content = (
            <Block
                extend={{
                    width: 'auto',
                    maxWidth: '604px',
                    margin: '80px 1rem'
                }}
            >
                <Block extend={{ height: theme.typeScale[6] }} />
                <Repeat small>
                    <H2 noMargin>Dina uppgifter</H2>
                </Repeat>
                <Repeat tiny>
                    <TextInput
                        label="Förnamn"
                        value={values.firstname}
                        error={errors.firstname}
                        disabled={isSubmitting}
                        onChange={(v: any) => setFormValue('firstname', v)}
                        onFocus={handleFocus}
                        fullWidth
                    />
                </Repeat>
                <Repeat tiny>
                    <TextInput
                        label="Efternamn"
                        value={values.lastname}
                        error={errors.lastname}
                        disabled={isSubmitting}
                        onChange={(v: any) => setFormValue('lastname', v)}
                        onFocus={handleFocus}
                        fullWidth
                    />
                </Repeat>
                <Repeat tiny>
                    <TextInput
                        label="Address"
                        value={values.address}
                        error={errors.address}
                        disabled={isSubmitting}
                        onChange={(v: any) => setFormValue('address', v)}
                        onFocus={handleFocus}
                        fullWidth
                    />
                </Repeat>
                <Repeat tiny>
                    <TextInput
                        label="Postnummer"
                        value={values.zipcode}
                        error={errors.zipcode}
                        disabled={isSubmitting}
                        onChange={(v: any) => setFormValue('zipcode', v)}
                        onFocus={handleFocus}
                        fullWidth
                    />
                </Repeat>
                <Repeat tiny>
                    <TextInput
                        label="Stad"
                        value={values.city}
                        error={errors.city}
                        disabled={isSubmitting}
                        onChange={(v: any) => setFormValue('city', v)}
                        onFocus={handleFocus}
                        fullWidth
                    />
                </Repeat>
                <Repeat tiny>
                    <TextInput
                        label="E-postadress"
                        value={values.email}
                        error={errors.email}
                        disabled={isSubmitting}
                        onChange={(v: any) => setFormValue('email', v)}
                        onFocus={handleFocus}
                        fullWidth
                    />
                </Repeat>
                <Repeat tiny>
                    <TextInput
                        label="Telefonnummer"
                        value={values.phone}
                        error={errors.phone}
                        disabled={isSubmitting}
                        onChange={(v: any) => setFormValue('phone', v)}
                        onFocus={handleFocus}
                        fullWidth
                    />
                </Repeat>
                <Repeat small>
                    <SelectInput
                        label="Välj din närmaste Volvohandlare för leverans"
                        value={values.retailer}
                        error={errors.retailer}
                        disabled={isSubmitting}
                        onChange={(v: any) => setFormValue('retailer', v)}
                        onFocus={handleFocus}
                        fullWidth
                    >
                        <option value="" key="-1">
                            Välj din Volvohandlare
                        </option>
                        {retailers.map((retailer) => (
                            <option
                                value={retailer.branchcode}
                                key={retailer.branchcode}
                            >
                                {retailer.city} - {retailer.name}
                            </option>
                        ))}
                    </SelectInput>
                </Repeat>
                <Repeat small>
                    <Button
                        primary
                        fullWidth
                        disabled={isSubmitting}
                        onClick={submitForm}
                    >
                        <ButtonContent>Anmäl intresse</ButtonContent>
                        <ButtonContent>
                            <IconArrowRight block />
                        </ButtonContent>
                    </Button>
                </Repeat>
                <Repeat small>
                    <Disclaimer>
                        <strong>Nästa steg</strong>
                        <div>
                            När du har skickat in din intresseanmälan så
                            återkommer den Volvohandlare du har valt till dig.
                            Tillsammans diskuterar ni igenom finansiering och
                            eventuella tillval som går att lägga till i
                            efterhand. Du förbinder dig inte att köpa någonting
                            genom att skicka in ditt intresse.
                        </div>
                    </Disclaimer>
                    <hr />
                    <Disclaimer>
                        Volvo Car Sverige kommer att lagra och hantera dina
                        persondata i enlighet med detta Integritetsmeddelande.
                        Genom att gå vidare ger jag medgivande till{' '}
                        <a
                            href="https://www.volvocars.com/se/sidfot/integritet?tag=in_01&menu=show"
                            title="Hantering av mina persondata"
                            target="_blank"
                            rel="noopener"
                            style={{ border: 'none' }}
                        >
                            hantering av mina persondata
                        </a>
                        .
                    </Disclaimer>
                </Repeat>
            </Block>
        );
    }

    return (
        <Flex extend={{ alignItems: 'center', width: '100%' }}>{content}</Flex>
    );
};
