export const fuelTypeI18n = (fuelTypeCode: string) => {
    switch (fuelTypeCode) {
        case 'plugin':
            return 'Laddhybrid';
        case 'petrol':
            return 'Bensin';
        case 'diesel':
            return 'Diesel';
        case 'electric':
            return 'Eldriven';
        default:
            return '';
    }
};

export const drivetrainI18n = (drivetrainCode: string) => {
    switch (drivetrainCode) {
        case 'fwd':
        case 'F':
            return 'Framhjulsdriven';
        case 'awd':
        case 'D':
            return 'Fyrhjulsdriven';
        default:
            return '';
    }
};