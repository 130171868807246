import * as React from 'react';
import { useQuery } from '@apollo/react-hooks';
import { Retailer } from '../../@types/gql/vcscloud/Retailer';
import { RetailersQuery } from '../../@types/gql/vcscloud/RetailersQuery';
import * as gql from '../../graphql/vcscloud';
import { RetailerContextType, RetailerContext } from './context';
import { vcscloud } from '../../clients';

export const RetailerContextContainer: React.SFC = ({ children }) => {
    const result = useQuery<RetailersQuery>(gql.RETAILERS_QUERY, {
        client: vcscloud
    });

    const context: RetailerContextType = [
        (result.data?.retailers as Retailer[] || []).sort((a, b) => {
            if (a.city === b.city) {
                return 0;
            }

            return a.city > b.city ? 1 : -1;
        }),
        result.loading
    ];

    return (
        <RetailerContext.Provider value={context}>
            {children}
        </RetailerContext.Provider>
    );
};
