import { ApolloClient, HttpLink, InMemoryCache } from 'apollo-boost';
import { setContext } from 'apollo-link-context';
import fetch from 'unfetch';

export default (uri: string, authorization?: string) => {
    const authLink = setContext((_, { headers }) => ({
        headers: {
            ...headers,
            authorization
        }
    }));

    const link = new HttpLink({
        uri,
        fetch,
        fetchOptions: { fetch },
        credentials: 'same-origin'
    });

    const cache = new InMemoryCache({
        addTypename: true
    });

    return new ApolloClient({
        link: authLink.concat(link),
        cache
    });
};
