import dataLayer from './dataLayer';

export const trackFormSubmitted = () => {
    dataLayer.push({ 'event': 'webform_action', 'formType': 'B2C- OR', 'formId': 'designers - choice - SE', 'type': 'Webform', 'result' : 'success', 'nonInteraction': '0' });
    dataLayer.push({
        'event': 'EventTracking',
        'category': 'Leads Private',
        'action': 'Designers Choice',
        'label': 'Köp bil'
    });
}