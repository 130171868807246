import React from 'react';
import { Flex, Text } from 'vcc-ui';

export interface IProps {
    mainTitle?: string | null;
    subTitle?: string | null;
    price?: string | null;
}

export const ModelTitle: React.FC<IProps> = ({
    mainTitle,
    subTitle,
    price
}) => (
    <Flex extend={{ '> *': { marginBottom: '.512rem' } }}>
        {mainTitle && (
            <Text variant="cook" subStyle="emphasis">
                {mainTitle}
            </Text>
        )}
        {subTitle && <Text variant="columbus">{subTitle}</Text>}
        {price && (
            <Text variant="bates" subStyle="emphasis">
                {price}
            </Text>
        )}
    </Flex>
);
