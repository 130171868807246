import { useMutation } from '@apollo/react-hooks';
import { CHECKOUT_STOCK_CAR_MUTATION } from '../../graphql/vcscloud';
import { IForm } from './formTypes';
import { ICheckoutCustomerForm } from './types';
import { CheckoutStockCarMutation, CheckoutStockCarMutationVariables } from '../../@types/gql/vcscloud/CheckoutStockCarMutation';
import { vcscloud } from '../../clients';

export const useCheckout = (form: IForm<ICheckoutCustomerForm>) => {
    const { values } = form;

    const {
        correlationId,
        retailer: retailerBranchCode,
        originUrl,
        checkoutType,
        loanDeposit,
        loanInterest,
        loanDuration,
        commonOrderNumber,
        ...customer
    } = values;

    const loanParameters = {
        deposit: 0,
        interest: 0,
        duration: 0,
        monthlyCost: 0
    };

    const market = 'se';

    const checkoutInput = {
        correlationId,
        originUrl,
        checkoutType,
        loanParameters
    };

    const variables = {
        retailerBranchCode,
        customer,
        checkoutInput,
        market,
        commonOrderNumber
    };

    const checkoutQueryOptions = {
        client: vcscloud,
        variables
    };

    return useMutation<CheckoutStockCarMutation, CheckoutStockCarMutationVariables>(
        CHECKOUT_STOCK_CAR_MUTATION,
        checkoutQueryOptions
    );
};
