import * as React from 'react';
import styled, { css } from 'styled-components';
import { size } from '../../layout';

type HeadingBaseProps = React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
> & {
    noMargin?: boolean;
    lineHeightThin?: boolean;
};

export const HeadingBase = styled.div<HeadingBaseProps>`
  margin-top: 0;
  margin-bottom: ${size(1)};
  font-family: ${props => props.theme.font.medium};
  font-weight: normal;
  line-height: 1.2;

  ${({ noMargin }) =>
        noMargin &&
        css`
      margin-bottom: 0;
    `}

  ${({ lineHeightThin }) =>
        lineHeightThin &&
        css`
      line-height: 1;
    `}
`;

type H1Props = HeadingBaseProps & {
    fontLight?: boolean;
    textCenter?: boolean;
};

export const H1 = styled(HeadingBase).attrs(() => ({
    as: 'h1'
})) <H1Props>`
  font-size: 2rem;

  ${props => props.theme.breakpoint.Sm} {
    font-size: 3.5rem;
  }

  ${({ fontLight }) =>
        fontLight &&
        css`
      font-family: ${props => props.theme.font.light};
      font-size: 1.5rem;

      ${props => props.theme.breakpoint.Sm} {
        font-size: 2rem;
      }

      ${props => props.theme.breakpoint.Lg} {
        font-size: 2rem;
      }
    `}

  ${({ textCenter }) =>
        textCenter &&
        css`
      text-align: center;
    `}
`;

type H2Props = HeadingBaseProps & {
    fontLight?: boolean;
    textCenter?: boolean;
};

export const H2 = styled(HeadingBase).attrs(() => ({
    as: 'h2'
})) <H2Props>`
  font-size: 1.5rem;

  ${({ fontLight }) =>
        fontLight &&
        css`
      font-family: ${props => props.theme.font.light};
    `}

  ${({ textCenter }) =>
        textCenter &&
        css`
      text-align: center;
    `}
`;

export const H3 = styled(HeadingBase).attrs(() => ({
    as: 'h3'
}))`
  font-size: 1.125rem;
`;

export const H4 = styled(HeadingBase).attrs(() => ({
    as: 'h4'
}))`
  font-size: 1rem;
`;

export const H5 = styled(HeadingBase).attrs(() => ({
    as: 'h5'
}))`
  font-size: 0.83rem;
`;

export const H6 = styled(HeadingBase).attrs(() => ({
    as: 'h6'
}))`
  font-size: 0.67rem;
`;

export const PreHeading = styled.div`
  font-size: 1.25rem;
  font-family: ${props => props.theme.font.light};
`;
