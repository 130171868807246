import React from 'react';
import { Flex, Text } from 'vcc-ui';

export interface IProps {
    title?: string;
    marginBottom?: string;
}

export const SpecList: React.FC<IProps> = ({
    title,
    children,
    marginBottom = '0.8rem'
}) => (
    <Flex
        extend={{
            '> * ': {
                marginBottom
            }
        }}
    >
        {title && (
            <Text
                variant="hillary"
                subStyle="emphasis"
                extend={{ marginBottom: '1rem' }}
            >
                {title}
            </Text>
        )}
        {children}
    </Flex>
);
