import React from 'react';
import { Flex, Text } from 'vcc-ui';

export interface IProps {
    icons: string[];
    title?: string | null | undefined;
}

export const SpecIcon: React.FC<IProps> = ({ icons, title }) => (
    <Flex>
        <Flex
            extend={{
                height: '1rem',
                flexDirection: 'row',
                alignItems: 'center',
                marginBottom: '.512rem'
            }}
        >
            {icons.map((icon, idx) => (
                <React.Fragment key={icon}>
                    <img src={icon} />
                    {idx < icons.length - 1 && (
                        <Text extend={{ margin: '0 .328rem' }}>+</Text>
                    )}
                </React.Fragment>
            ))}
        </Flex>
        {title && (
            <Text variant="hillary" subStyle="emphasis">
                {title}
            </Text>
        )}
    </Flex>
);
