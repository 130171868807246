import * as React from 'react';
import { CLEAR_FORM_SUBMIT_FLAG, SET_FORM_VALUE, SUBMIT_FORM } from './constants';
import { getInitialState, reducer } from './reducer';
import { useCheckout } from './useCheckout';

import { FormErrors } from './formTypes';
import { ICheckoutCustomerForm } from './types';
import { trackFormSubmitted } from '../../tracking';

export interface ICheckoutFormContext {
  values: ICheckoutCustomerForm;
  success: any;
  error: any;
  errors: FormErrors<ICheckoutCustomerForm>;
  isSubmitting: boolean;
  loading: boolean;
  setFormValue: (name: string, value: any) => void;
  submitForm: () => void;
  handleFocus: () => void;
}

export const useCheckoutForm = (commonOrderNumber: string): ICheckoutFormContext => {
  const [form, dispatch] = React.useReducer(
    reducer,
    getInitialState({ commonOrderNumber })
  );

  const { values, errors, isSubmitting } = form;

  const [handleCheckout, { data, error, loading }] = useCheckout(form);

  const success = data?.checkoutStockCar?.success || false;

  React.useEffect(() => {
    const hasErrors =
      Object.keys(errors).findIndex((key: string) => errors[key] !== undefined) !== -1;

    (async () => {
      if (isSubmitting && !hasErrors) {
        trackFormSubmitted();
        await handleCheckout();
      }

      dispatch({
        type: CLEAR_FORM_SUBMIT_FLAG
      });
    })();
  }, [isSubmitting]);

  const setFormValue = React.useCallback(
    (name: string, value: any) =>
      dispatch({
        name,
        value,
        type: SET_FORM_VALUE
      }),
    [dispatch]
  );

  const submitForm = React.useCallback(() => {
    dispatch({
      type: SUBMIT_FORM
    });
  }, [dispatch]);

  const handleFocus = () => {
    return;
  };

  return {
    values,
    success,
    error,
    errors,
    isSubmitting,
    loading,
    setFormValue,
    submitForm,
    handleFocus
  };
};
