const _colors = {
    black: '#000',
    white: '#fff',
    gray: '#ddd',
    grayLight: '#fafafa',
    grayLightDarker: '#f0f0f0',
    grayDark: '#757575',
    brand: {
        blue: '#2a78c6',
        blueState: '#145081',
        blueGray: '#141820',
        gray: '#161618'
    }
};

const _breakpoints = {
    Xs: '400px',
    LtXs: '399px',
    Sm: '600px',
    LtSm: '599px',
    Md: '900px',
    LtMd: '899px',
    Lg: '1200px',
    LtLg: '1199px',
    aspectRatio: '5/4'
};

export const theme = {
    color: {
        bg: _colors.grayLight,
        bgLight: _colors.white,
        bgDark: _colors.brand.blueGray,
        primary: _colors.brand.blue,
        primaryHover: _colors.brand.blueState,
        secondary: _colors.brand.gray,
        complement: _colors.black,
        accent: _colors.grayLight,
        accentDark: _colors.grayLightDarker,
        border: _colors.gray,
        textDark: _colors.brand.gray,
        textDarkLighten: _colors.grayDark,
        textLight: _colors.white,
        textOnPrimary: _colors.white,
        disabledBg: _colors.gray,
        disabledText: _colors.grayDark,
        darkFade05: 'rgba(0, 0, 0, 0.05)',
        darkFade15: 'rgba(0, 0, 0, 0.15)',
        bgLightFade00: 'rgba(255, 255, 255, 0)',
        bgDarkFade00: 'rgba(20, 24, 32, 0)',
        accentDarkFade00: 'rgba(240, 240, 240, 0)'
    },
    font: {
        light: "'Volvo Novum Light', sans-serif",
        regular: "'Volvo Novum Regular', sans-serif",
        medium: "'Volvo Novum Medium', sans-serif",
        heading: "'Volvo Broad', sans-serif",
        lineHeight: '1.5'
    },
    breakpoint: {
        Xs: `@media (min-width: ${_breakpoints.Xs})`,
        LtXs: `@media (max-width: ${_breakpoints.LtXs})`,
        Sm: `@media (min-width: ${_breakpoints.Sm})`,
        LtSm: `@media (max-width: ${_breakpoints.LtSm})`,
        Md: `@media (min-width: ${_breakpoints.Md})`,
        LtMd: `@media (max-width: ${_breakpoints.LtMd})`,
        Lg: `@media (min-width: ${_breakpoints.Lg})`,
        LtLg: `@media (max-width: ${_breakpoints.LtLg})`,
        LandscapeSm: `@media (min-width: ${_breakpoints.Sm}) and (min-aspect-ratio: ${_breakpoints.aspectRatio})`,
        LandscapeMd: `@media (min-width: ${_breakpoints.Md}) and (min-aspect-ratio: ${_breakpoints.aspectRatio})`,
        LandscapeLg: `@media (min-width: ${_breakpoints.Lg}) and (min-aspect-ratio: ${_breakpoints.aspectRatio})`,
        LandscapeLtMd: `@media (max-width: ${_breakpoints.LtLg}) and (orientation: landscape)`
    },
    distances: {
        baseUnit: '16px',
        headerHeight: '60px',
        containerMaxWidth: '1288px'
    },
    boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1);',
    boxShadowHover: '0 1px 2px 0 rgba(0, 0, 0, 0.3);',
    typeScale: [
        '.328em',
        '.41em',
        '.512em',
        '.0.64em',
        '0.8em',
        '1em',
        '1.25em',
        '1.563em',
        '1.953em',
        '2.441em',
        '3.052em',
        '3.815em',
        '4.768em',
        '5.96em'
    ]
};
