import React, { useEffect, useState, useContext } from 'react';
import { Button, Block, Flex, Inline } from 'vcc-ui';
import { useSpring, animated } from 'react-spring/web.cjs';
import { ScrollTopProvider } from '../root';
import { IconArrowRight } from '../icon';
import { theme } from '../../layout/theme';

export interface IProps {
    handleCheckout: () => void;
}

export const StickyCTA: React.FC<IProps> = ({ handleCheckout }) => {
    const scrollTop = useContext(ScrollTopProvider);

    const [visible, setVisible] = useState(false);

    useEffect(() => {
        setVisible(scrollTop > 50);
    }, [scrollTop]);

    const enterAnimation = useSpring({
        transform: `translateY(${visible ? 0 : theme.typeScale[11]})`
    });

    return (
        <animated.div
            style={{
                ...enterAnimation,
                position: 'fixed',
                bottom: 0,
                width: '100%'
            }}
        >
            <Flex
                extend={{
                    width: '100%',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: 'white',
                    height: theme.typeScale[11],
                    borderTop: '2px solid #EFECE9',
                    [theme.breakpoint.Md]: {
                        display: 'none'
                    }
                }}
            >
                <Block extend={{ width: '90%', maxWidth: '350px' }}>
                    <Button intent="primary" onClick={handleCheckout}>
                        Gå vidare
                        <Inline
                            extend={{
                                marginLeft: 10
                            }}
                        >
                            <IconArrowRight />
                        </Inline>
                    </Button>
                </Block>
            </Flex>
        </animated.div>
    );
};
