import * as React from 'react';
import styled, { css } from 'styled-components';
import { size } from '../../layout';

export const ButtonReset = styled.button`
  display: inline-block;
  vertical-align: middle;
  padding: 0;
  margin: 0;
  background-color: transparent;
  border: 0;
  border-radius: 0;
  font-family: ${props => props.theme.font.regular};
  font-size: 1em;
  color: currentColor;
  line-height: 1.2;
  text-align: center;
  text-decoration: none;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0.1);
  cursor: pointer;

  :focus {
    outline: none;
  }

  ::-moz-focus-inner {
    border: 0;
  }
`;

type ButtonResetProps = React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
> & {
    primary?: boolean;
    uppercase?: boolean;
    fullWidth?: boolean;
    rounded?: boolean;
    outline?: boolean;
    tiny?: boolean;
};

export default styled(ButtonReset) <ButtonResetProps>`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: ${size(1)} ${size(3)};
    font-size: 1rem;
    line-height: 1.2;
    text-decoration: none;
    transform-origin: 50% 50%;
    transition: 200ms ease, transform 300ms cubic-bezier(.47, 1.64, 0.41, 0.8);

    :active {
        transform: scale(0.97);
    }

    &[disabled] {
        background-color: ${props => props.theme.color.disabledBg};
        border-color: ${props => props.theme.color.disabledBg};
        color: ${props => props.theme.color.disabledText};
    }

    ${({ primary }) =>
        primary &&
        css`
        background-color: ${props => props.theme.color.primary};
        color: ${props => props.theme.color.textOnPrimary};
        border: 1px solid ${props => props.theme.color.primary};
        box-shadow: ${props => props.theme.boxShadow};

        &:not([disabled]) {
          &:hover {
            background-color: ${props => props.theme.color.primaryHover};
            border-color: ${props => props.theme.color.primaryHover};
          }
        }

        &[disabled] {
          box-shadow: none;
        }
      `}

    ${({ fullWidth }) =>
        fullWidth &&
        css`
        display: flex;
        width: 100%;
      `}

    ${({ tiny }) =>
        tiny &&
        css`
        padding: ${size(0.5)} ${size(1)};
        font-size: 0.75rem;
      `}

    ${({ rounded }) =>
        rounded &&
        css`
        border-radius: 4px;
      `}

    ${({ uppercase }) =>
        uppercase &&
        css`
        text-transform: uppercase;
      `}

    ${({ outline }) =>
        outline &&
        css`
        background-color: transparent;
        color: ${props => props.theme.color.primary};
        border: 1px solid ${props => props.theme.color.primary};

        &:not([disabled]) {
          &:hover {
            color: ${props => props.theme.color.textOnPrimary};
            background-color: ${props => props.theme.color.primary};
          }
        }
      `}
`;

type ButtonContentProps = React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
> & {
    small?: boolean;
};

export const ButtonContent = styled.div<ButtonContentProps>`
  ${({ small }) =>
        small &&
        css`
      font-size: 0.75rem;
    `}

  & + & {
    margin-left: ${size(0.5)};
  }
`;
