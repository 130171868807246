import styled from 'styled-components';

export default styled.div`
  color: ${props => props.theme.color.textDarkLighten};
  font-size: 0.875rem;
  font-family: "Volvo Novum Light";

  a {
    color: ${props => props.theme.color.primary};
    text-decoration: none;

    :hover {
      text-decoration: underline;
    }
  }
`;
