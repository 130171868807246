import * as uuid from 'uuid';
import { CLEAR_FORM_SUBMIT_FLAG, SET_FORM_VALUE, SUBMIT_FORM } from './constants';
import { fieldFormatters } from './fieldFormatters';
import { IForm, IFormAction, IRule } from './formTypes';
import { rules } from './rules';
import { ICheckoutCustomerForm } from './types';
import { CHECKOUT_TYPE } from '../../@types/gql/vcscloud/globalTypes';

export type FormValidation<T> = {
    [K in keyof T]: {
        newValue: T[K];
        error: string;
    };
};

export const getInitialState = (
    values: Partial<ICheckoutCustomerForm> = {}
): IForm<ICheckoutCustomerForm> => ({
    values: {
        checkoutType: CHECKOUT_TYPE.DESIGNERS_CHOICE,
        firstname: '',
        lastname: '',
        address: '',
        zipcode: '',
        city: '',
        email: '',
        phone: '',
        retailer: '',
        messageToRetailer: '',
        originUrl: window.location.href,
        newsletterOptIn: false,
        country: 'se',
        correlationId: uuid.v4(),
        loanDeposit: 0,
        loanDuration: 0,
        loanInterest: 0,
        commonOrderNumber: '',
        ...values
    },
    errors: {},
    isSubmitting: false
});

export const validateField = (name: keyof ICheckoutCustomerForm, value: any) => {
    const formatter = fieldFormatters[name];

    let newValue = value;

    if (formatter !== undefined) {
        newValue = formatter(value);
    }

    const fieldRules = rules[name];

    let error: string | undefined;

    if (fieldRules !== undefined) {
        error = (fieldRules as IRule<any>[]).find(rule => !rule.test(newValue))?.message;
    }

    return {
        error,
        newValue
    };
};

export const mutateFormStateFromValueSet = (
    state: IForm<ICheckoutCustomerForm>,
    name: keyof ICheckoutCustomerForm,
    value: any
): IForm<ICheckoutCustomerForm> => {
    const { newValue, error } = validateField(name, value);

    if (state.isSubmitting) {
        return state;
    }

    return {
        values: {
            ...state.values,
            [name]: newValue
        },
        errors: {
            ...state.errors,
            [name]: error
        },
        isSubmitting: false
    };
};

export const mutateFormFromSubmit = (state: IForm<ICheckoutCustomerForm>): IForm<ICheckoutCustomerForm> => {
    const validatedFields = Object.keys(state.values).reduce(
        (fields, name) => ({
            ...fields,
            [name]: validateField(name as any, state.values[name])
        }),
        {} as FormValidation<ICheckoutCustomerForm>
    );

    return {
        values: Object.keys(validatedFields).reduce(
            (values: any, name) => ({
                ...values,
                [name]: validatedFields[name].newValue
            }),
            {}
        ),
        errors: Object.keys(validatedFields).reduce(
            (errors: any, name) => ({
                ...errors,
                [name]: validatedFields[name].error
            }),
            {}
        ),
        isSubmitting: true
    };
};

export const reducer = (
    state: IForm<ICheckoutCustomerForm>,
    action: IFormAction
): IForm<ICheckoutCustomerForm> => {
    switch (action.type) {
        case SET_FORM_VALUE:
            const newV = mutateFormStateFromValueSet(state, action.name, action.value);
            return newV;

        case SUBMIT_FORM:
            return mutateFormFromSubmit(state);

        case CLEAR_FORM_SUBMIT_FLAG:
            return { ...state, isSubmitting: false };

        default:
            return state;
    }
};
