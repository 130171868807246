import * as React from 'react';
import styled, { css } from 'styled-components';
import { size } from '../../layout';

type Props = React.SelectHTMLAttributes<any> & {
  error?: string;
  fullWidth?: boolean;
  label?: string;
  primary?: boolean;
  uppercase?: boolean;
  onChange?: (value: string) => void;
  onFocus?: () => void;
};


export const Label = styled.label`
  display: inline-flex;
  font-family: ${props => props.theme.font.light};
  color: ${props => props.theme.color.textDarkLighten};
  margin-bottom: ${size(0.25)};
`;

export const ErrorMessage = styled.div`
  display: none;
  background-color: #fbeaed;
  color: #eb3a47;
  font-family: ${props => props.theme.font.light};
  height: 3em;
  align-items: center;
  justify-content: flex-start;
  padding: 0 ${size(1)};
`;

export const Wrapper = styled.div``;

export const SelectInputReset = styled.select`
  display: inline-block;
  vertical-align: middle;
  padding: 0;
  margin: 0;
  background-color: transparent;
  border: 0;
  border-radius: 0;
  font-family: ${props => props.theme.font.regular};
  font-size: 1em;
  color: currentColor;
  line-height: 1.2;
  text-align: center;
  text-decoration: none;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0.1);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: pointer;
  height: auto !important;

  :focus {
    outline: none;
  }

  ::-moz-focus-inner {
    border: 0;
  }

  ::-ms-expand {
    display: none;
  }
`;

type SelectInputProps = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLSelectElement>,
  HTMLSelectElement
> & {
  primary?: boolean;
  uppercase?: boolean;
  fullWidth?: boolean;
  validation?: boolean;
};

export const SelectInputElement = styled(SelectInputReset)<SelectInputProps>`
  display: inline-flex;
  align-items: center;
  background-color: #ffffff;
  border: 1px solid #d5d5d5;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='15' height='15' viewBox='0 0 13 6' fill='%231c6bba' %3E %3Cpath d='M6.49987275 4.13397441L11.78483479.5 12.5 1.37426775 6.49986464 5.5.5 1.3743604l.7151756-.87426061z'%3E %3C/path%3E %3C/svg%3E");
  background-size: 15px 15px;
  background-position: right 18px center;
  background-repeat: no-repeat;
  box-sizing: border-box;
  justify-content: flex-start;
  padding: ${size(1)} ${size(1.5)};
  font-size: 1rem;
  line-height: 1.5;
  text-align: left;
  text-decoration: none;
  transform-origin: 50% 50%;
  transition: 200ms ease, transform 300ms cubic-bezier(.47, 1.64, 0.41, 0.8);
  font-family: ${props => props.theme.font.light};

  &:hover {
    cursor: pointer;
  }

  &:focus {
        border-color: #1c6bba;
  }

  &[disabled] {
      cursor: not-allowed;
      border-color: #ebebeb;
      color: #d5d5d5;

      &::placeholder {
        color: #d5d5d5;
      }
  }

  ${({ fullWidth }) =>
    fullWidth &&
    css`
      display: flex;
      width: 100%;
    `}

  ${({ uppercase }) =>
    uppercase &&
    css`
      text-transform: uppercase;
    `}

  ${({ validation }) =>
    validation &&
    css`
      border: 1px solid #bf2012;

      &:focus {
        border: 1px solid #bf2012;
      }

      + ${ErrorMessage} {
        display: flex;
      }
    `}

`;


const SelectInput = ({ error, label, onChange, onFocus, ...elementProps }: Props) => {
  const handleFocus = () => {
    if (onFocus) {
      onFocus();
    }
  };

  return (
    <Wrapper>
      {label ? <Label>{label}</Label> : null}
      <SelectInputElement
        {...elementProps}
        validation={!!error}
        onChange={(e: any) => onChange && onChange(e.target.value)}
        onFocus={handleFocus}
      />
      {error ? <ErrorMessage>{error}</ErrorMessage> : null}
    </Wrapper>
  );
};

export default SelectInput;
