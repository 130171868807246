import dataLayer from './dataLayer';

export const trackFormOpened = () => {
    dataLayer.push({ 'event': 'webform_action', 'formType': 'B2C-OR', 'formId': 'designers-choice-SE', 'type': 'Webform', 'result': 'load', 'nonInteraction': '1' });
    dataLayer.push({
        'event': 'EventTracking',
        'category': 'Leads Private',
        'action': 'Designers Choice',
        'label': 'Påbörja beställning'
    });
}