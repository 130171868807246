import * as React from 'react';
import styled, { css } from 'styled-components';

const Icon = styled.svg.attrs((props: any) => ({
    xmlns: 'http://www.w3.org/2000/svg',
    size: props.size || '1em'
}))`
  display: inline-block;
  vertical-align: middle;
  width: ${props => props.size};
  height: ${props => props.size};
  fill: currentColor;

  ${(props: any) =>
        props.block &&
        css`
      display: block;
    `}
`;

export const IconCheck = (props: any) => (
    <Icon viewBox="0 0 18 18" {...props}>
        <path d="M17.8 3.7L5.9 15.6l-.1.1L.2 10l1.4-1.4 4.3 4.3L16.5 2.3l1.3 1.4z" />
    </Icon>
);

export const IconArrowLeft = (props: any) => (
    <Icon viewBox="0 0 21 21" {...props}>
        <path d="M21 11H2.2l6.5 6.5-.7.7-7.7-7.7L8 2.8l.7.7L2.2 10H21v1z" />
    </Icon>
);

export const IconArrowRight = (props: any) => (
    <Icon viewBox="0 0 21 21" {...props}>
        <path d="M20.7 10.5L13 18.2l-.7-.7 6.5-6.5H0v-1h18.8l-6.5-6.5.7-.7 7.7 7.7z" />
    </Icon>
);

export const IconInfo = (props: any) => (
    <Icon viewBox="0 0 24 24" {...props}>
        <path d="M14.1 16.7v.5H9.9v-.5c1 0 1-1 1-1V12c0-1-1-1-1-1v-.5s2.1-.5 2.6-.5.5.5.5.5v5.2c0 1 1.1 1 1.1 1zm-2.4-7.8c.7 0 1.3-.6 1.3-1.3 0-.7-.6-1.3-1.3-1.3-.7 0-1.3.6-1.3 1.3 0 .7.6 1.3 1.3 1.3zM24 12c0 6.6-5.4 12-12 12S0 18.6 0 12 5.4 0 12 0s12 5.4 12 12zm-1.1 0C22.9 6 18 1.1 12 1.1S1.1 6 1.1 12 6 22.9 12 22.9 22.9 18 22.9 12z" />
    </Icon>
);

export const IconEngine = (props: any) => (
    <Icon viewBox="0 0 24 24" {...props}>
        <path d="M9.6 11.1h4.7v1H9.6v-1zm0 3.4h4.7v-1H9.6v1zm13.9-6.4v10.2c0 .8-.1 1.3-.5 1.6-.4.4-.9.4-1.4.4h-.1c-.3 0-.6-.1-.8-.1-.6-.2-.9-.8-.9-1.4v-.7H19v1.1c0 1.1-.6 1.5-1 1.6H9c-.7 0-1.3-.3-1.9-.9l-1.3-1.5H4.4c-1.2 0-1.6-.9-1.6-1.5v-4H1.5v5.5h-1V7.9h1v4h1.3V9.4c0-1.1.7-1.5 1.1-1.6H5.3v-.6c0-1 .7-1.4 1.1-1.4h4.5V4H7.1V3h8.5v1h-3.8v1.7h3.6c.6 0 1.3.3 1.7.8l1.7 2h.8v-.8c0-.6.4-1.2 1-1.4.2-.1.5-.1.7-.1h.1c.5 0 1 0 1.4.4.5.3.7.8.7 1.5zm-1 0c0-.6-.1-.8-.2-.9-.1-.1-.4-.1-.7-.1h-.1c-.1 0-.3 0-.4.1-.2.1-.3.3-.3.5v1.9h-2.3l-1.9-2.4c-.3-.3-.6-.4-1-.4H6.5s-.2.1-.2.4v1.6H4.1c-.1 0-.3.1-.3.6v7.5c0 .2.1.5.6.5h1.7l1.6 1.8c.3.3.8.5 1.1.5h8.8c.1 0 .2-.2.2-.6V17h2.8v1.7c0 .2.1.4.3.4.1 0 .2.1.4.1h.1c.3 0 .6 0 .7-.1.1-.1.2-.2.2-.9V8.1z" />
    </Icon>
);

export const IconInterior = (props: any) => (
    <Icon viewBox="0 0 24 24" {...props}>
        <path d="M10.2 8.2c0-.3.2-.5.5-.5h2.6c.3 0 .5.2.5.5s-.2.5-.5.5h-2.6c-.3 0-.5-.2-.5-.5zm0 2.4h3.7c.3 0 .5-.2.5-.5s-.2-.5-.5-.5h-3.7c-.3 0-.5.2-.5.5-.1.3.2.5.5.5zm9.7 7.2c-.2.3-.5.7-.7 1 .1 0 .1.1.1.1.5.3 1 1 1 1.9-.1 1.5-1.6 3.2-8.2 3.2S4 22.2 3.9 20.8c0-1 .6-1.6.9-1.9l.1-.1c-.2-.3-.5-.6-.7-.9-.3-.4-.4-.9-.4-1.3C3.6 12.9 5.5 8 5.9 7 6.2 6 7 5.7 7.6 5.7l.3-.4-.2-.2c-.5-.4-.4-1-.4-1.3 0-.1.4-1.2.9-2.1C8.7.5 9.6 0 9.7 0h4.9s1 .4 1.5 1.6c.4 1 .8 2.1.8 2.1.1.4.1 1-.4 1.4-.1.1-.2.1-.4.2l.3.3c.6 0 1.4.3 1.8 1.3.4.9 2.3 5.9 2.1 9.6 0 .4-.1.9-.4 1.3zM8.4 4.2c.3.2 1.7.2 3.7.2 1.5 0 3.5 0 3.8-.2V4s-.4-1.1-.8-2c-.3-.6-.7-.9-.8-1H10c-.2.1-.6.4-.8 1-.4.9-.8 2-.8 2 0 .1-.1.2 0 .2zM7.3 18.4c1.1 0 2.6-.1 4.7-.1s3.6 0 4.6.1c-1.1-2.9-1.1-10.1-1.1-12l-.7-.9c-.7 0-1.6.1-2.8.1H9.1l-.7.8c0 2 0 9.1-1.1 12zm-1.3.1h.2c.8-1.4 1.1-7.6 1.1-11.7-.2.1-.4.3-.5.6-.2.6-2.2 5.5-2 9.1 0 .2.1.5.2.7.4.5.8 1 1 1.3zm13.2 2.1c.1-.6-.5-.9-.6-1-1.2-.3-11.8-.3-13.1 0 0 0-.6.3-.6 1 0 .5.7 2.2 7.2 2.2 6.4.1 7.1-1.6 7.1-2.2zm-2-13.3c-.1-.3-.3-.4-.5-.5 0 4.1.3 10.3 1.2 11.7h.1c.2-.3.6-.8 1-1.3.2-.2.2-.5.2-.7.2-3.6-1.6-8.3-2-9.2z" />
    </Icon>
);

export const IconList = (props: any) => (
    <Icon viewBox="0 0 24 24" {...props}>
        <path d="M5.8 8.2c0 .4-.3.7-.7.7-.4 0-.7-.3-.7-.7 0-.4.3-.7.7-.7.4 0 .7.3.7.7zm-.7 3.3c-.4 0-.7.3-.7.7 0 .4.3.7.7.7.4 0 .7-.3.7-.7 0-.5-.3-.7-.7-.7zm0 4c-.4 0-.7.3-.7.6 0 .4.3.7.7.7.4 0 .7-.3.7-.7 0-.4-.3-.6-.7-.6zm2.6-6.8H20v-.9H7.7v.9zm0 7.8H20v-.9H7.7v.9zm0-3.9H20v-.9H7.7v.9zM24 4.8v14.8c0 .8-.7 1.5-1.5 1.5h-21c-.8 0-1.5-.7-1.5-1.5V4.8c0-.8.7-1.5 1.5-1.5h20.9c.4 0 .8.2 1.1.5.4.2.5.6.5 1zm-1 0c0-.3-.3-.5-.6-.5H1.5c-.3 0-.5.2-.5.5v14.8c0 .3.3.5.6.5h20.9c.3 0 .6-.2.6-.5V4.8z" />
    </Icon>
);

export const IconCar = (props: any) => (
    <Icon viewBox="0 0 27 27" {...props}>
        <path d="M17.9 13.3c-.4 0-3.4 0-4.3-.1h-.1c-1 0-4 0-4.2.1-.4.1-.8 0-.8.6s.2 1.6.2 1.6.1.9.8 1c.8.1 2.4.2 3.8.2h.6c1.4 0 3-.2 3.8-.2.7-.1.8-1 .8-1s.1-.9.2-1.6c0-.6-.4-.5-.8-.6zm-.5 2.1v.1c-.2 0-.3 0-.5.1-.8.1-2.1.2-3.1.2h-.6c-1.1 0-2.2-.1-3.1-.2-.2 0-.3-.1-.5-.1v-.1c0-.2-.1-.6-.1-1 .3 0 1.3-.1 4-.1v-.1c2.7 0 3.6.1 4 .1l-.1 1.1zM27 8.8c0-.7-.9-1.1-1.7-1.3-.6-.1-1.1 0-1.4.3-.3.2-.4.6-.4.9v.5l-2.1-3.8c-.7-1.3-2.2-1.8-3.3-1.8H9c-1.1 0-2.5.4-3.4 1.8l-2 3.7v-.3c0-.4-.2-.7-.4-.9-.4-.3-.9-.4-1.5-.3-.8.1-1.7.5-1.7 1.2s1 1.2 1.9 1.3c.4.1.8.1 1.1.2l-.2.4c-.5 1-1 2.7-1 3.9v7.7c0 .6.5 1.1 1.1 1.1h1.6c.5 0 .9-.3 1-.8v-.1l.5-2.1h14.8l.5 1.9v.1c.1.4.5.8 1 .8h1.6c.6 0 1.1-.5 1.1-1.1v-7.6c0-1.1-.4-2.8-1-3.9v-.3c.3 0 .7-.1 1.1-.2.9-.1 1.9-.5 1.9-1.3zm-24.5.4c-.2 0-.3 0-.5-.1-.4 0-.7-.2-.9-.3.1-.1.4-.2.7-.2.3 0 .5 0 .6.1l.1.1v.4zm21.6 13h-1.6l-.5-1.9-.2-.9H5.2l-.2.8-.5 1.9v.1H2.9v-7.7c0-1 .4-2.5.8-3.3l2.8-5.1C7.2 5 8.3 4.8 8.9 4.8H18c.6 0 1.9.3 2.4 1.2l2.8 5.2c.5.9.8 2.4.8 3.3v7.7zm.4-13v-.4s0-.1.1-.1c.1-.1.2-.1.4-.1h.2c.4.1.6.2.8.2-.2.1-.5.3-1 .3-.2.1-.4.1-.5.1zm-2.1 3.5l1-.1.2 2.4-1 .1v-.6c-.2.1-.5.1-.9.2-1.2.1-1.7.1-1.9.1v-1c.2 0 .7 0 1.8-.1.4-.1.7-.1 1-.2l-.2-.8zm-16.9.9c1.1.1 1.6.1 1.8.1v1c-.2 0-.8 0-1.9-.1-.4-.1-.7-.1-.9-.2v.6l-1-.1.2-2.4 1 .1-.1.8c.2.1.5.2.9.2z" />
    </Icon>
);

export const IconSafety = (props: any) => (
    <Icon viewBox="0 0 22 22" {...props}>
        <path d="M11 5.9c1.6 0 3-1.3 3-3 0-1.6-1.3-3-3-3C9.4 0 8 1.3 8 3c0 1.6 1.4 2.9 3 2.9zM11 1c1.1 0 2 .9 2 2s-.9 2-2 2-2-.9-2-2 .9-2 2-2zm7.5 15.6c-.1.2-.3.2-.4.2-.1 0-.2 0-.3-.1-7.2-4.3-13.4-.2-13.7 0-.2.2-.5.1-.7-.1-.2-.2-.1-.5.1-.7L17.1 5.6c.2-.2.5-.1.7.1.2.2.2.5-.1.7l-10.4 8c2.8-.7 6.8-1 11 1.6.3.1.4.4.2.6zm-2.9-3.1v-.2c-.1-1-.6-2.4-.8-3 0-.1-.1-.2-.2-.3l.8-.6c.1.1.3.3.3.6.3.6.8 2 .9 3.2v.5h-1v-.2zm-.1 3.3l1 .1c-.2 1.8-.8 3.4-1.8 5l-.9-.5c.9-1.4 1.5-3 1.7-4.6zm-9 0c.2 1.6.7 3.1 1.7 4.6l-.9.6c-1-1.6-1.6-3.3-1.8-5l1-.2zm0-3.8h-1v-.3c.2-2.8 2.5-6 2.6-6.1l.1-.2h5.5l.3.4-.5.4v.2H8.7c-.5.7-2.1 3.2-2.2 5.3v.3z" />
    </Icon>
);

export const IconPackages = (props: any) => (
    <Icon viewBox="0 0 22 22" {...props}>
        <path d="M.8 6.4l10 5c.1 0 .1.1.2.1s.2 0 .2-.1l10-5c.2-.1.3-.3.3-.4s-.1-.4-.3-.4l-10-5c-.1-.1-.3-.1-.4 0l-10 5c-.2 0-.3.2-.3.4s.1.4.3.4zM11 1.6L19.9 6 11 10.4 2.1 6 11 1.6zm10.4 14.2c.1.2 0 .5-.2.7l-10 5c-.1 0-.1.1-.2.1s-.2 0-.2-.1l-10-5c-.2-.1-.3-.4-.2-.7.1-.2.4-.3.7-.2l9.8 4.9 9.8-4.9c.1-.2.4-.1.5.2zm0-5c.1.2 0 .5-.2.7l-10 5c-.1 0-.1.1-.2.1s-.2 0-.2-.1l-10-5c-.2-.1-.3-.4-.2-.7.1-.2.4-.3.7-.2l9.8 4.9 9.8-4.9c.1-.2.4-.1.5.2z" />
    </Icon>
);

export const IconSearch = (props: any) => (
    <Icon viewBox="0 0 16 16" {...props}>
        <path d="M15.5 15.3L11 10.8c1.2-1.1 2-2.7 2-4.5C13 2.9 10.2.1 6.8.1S.5 2.8.5 6.2s2.8 6.2 6.2 6.2c1.3 0 2.5-.4 3.5-1.1l4.6 4.6.7-.6zM1.4 6.2C1.4 3.3 3.8 1 6.7 1S12 3.3 12 6.2c0 1.5-.7 2.9-1.7 3.9l-.7.5c-.8.5-1.8.8-2.8.8-3 .1-5.4-2.3-5.4-5.2z" />
    </Icon>
);

export const IconClose = (props: any) => (
    <Icon viewBox="0 0 16 16" {...props}>
        <path d="M15.7 14.3c.4.4.4 1 0 1.4-.2.2-.5.3-.7.3s-.5-.1-.7-.3L8 9.4l-6.3 6.3c-.2.2-.4.3-.7.3s-.5-.1-.7-.3c-.4-.4-.4-1 0-1.4L6.6 8 .3 1.7C-.1 1.3-.1.7.3.3c.4-.4 1-.4 1.4 0L8 6.6 14.3.3c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4L9.4 8l6.3 6.3z" />
    </Icon>
);

export const IconChevronUp = (props: any) => (
    <Icon viewBox="0 0 18 18" {...props}>
        <path d="M.3 13L9 4.3l8.7 8.7-.7.7-8-8-8 8-.7-.7z" />
    </Icon>
);

export const IconChevronDown = (props: any) => (
    <Icon viewBox="0 0 18 18" {...props}>
        <path d="M17.7 5L9 13.7.3 5l.7-.7 8 8 8-8 .7.7z" />
    </Icon>
);

export const IconChevronDownBold = (props: any) => (
    <Icon viewBox="0 0 16 16" {...props}>
        <path d="M16 5.8l-8 5.5-8-5.5 1-1.2 7 4.8 7-4.8 1 1.2z" />
    </Icon>
);

export const IconChevronLeft = (props: any) => (
    <Icon viewBox="0 0 18 18" {...props}>
        <path d="M13 17.7L4.3 9 13 .3l.7.7-8 8 8 8-.7.7z" />
    </Icon>
);

export const IconChevronRight = (props: any) => (
    <Icon viewBox="0 0 18 18" {...props}>
        <path d="M5 .3L13.7 9 5 17.7l-.7-.7 8-8-8-8L5 .3z" />
    </Icon>
);

export const IconEmission = (props: any) => (
    <Icon viewBox="0 0 22 22" {...props}>
        <path d="M11 0C4.9 0 0 4.9 0 11s4.9 11 11 11 11-4.9 11-11S17.1 0 11 0zm0 21C5.6 21 1 16.4 1 11S5.6 1 11 1s10 4.5 10 10-4.6 10-10 10zm-2.7-9.7l.9.4v.1c-.6 1.6-1.9 1.7-2.2 1.7-.6 0-.9-.2-1.3-.3-1.2-.7-1.2-2.2-1.2-2.6v-.1c0-.1 0-.6.2-1.2C5.1 8 6.1 7.5 7 7.5c.9 0 1.5.4 1.8.9.2.3.3.7.3.8l-1 .2c0-.3-.3-1-1.2-1-.6 0-1.5.5-1.5 2s.7 2.1 1.5 2.1c1 0 1.3-.9 1.4-1.2zM13.4 8c-.4-.4-.9-.5-1.4-.5-.5 0-.9.1-1.4.4-.5.4-1.2 1.3-1.2 2.6 0 1.5.7 2.3 1.4 2.7.2.1.5.2 1 .2 1.5 0 2.1-1.2 2.3-1.9.1-.3.1-.7.1-1 .3-1.3-.3-2.1-.8-2.5zM12 12.5c-.7 0-1.5-.5-1.5-2s.8-2 1.5-2c1 0 1.4 1 1.5 1.4 0 .2.1.5.1.7-.1 1.4-1 1.9-1.6 1.9zm6.4.3v.7h-2.9v-.6c1-.6 2.1-1.5 2.1-2.2 0-.2-.1-.5-.6-.5-.3 0-.6.2-.6.5v.3h-.8v-.1c0-.4.3-1.3 1.5-1.3s1.5.7 1.5 1.2c0 .8-1 1.6-1.9 2.1h1.9l-.2-.1z" />
    </Icon>
);

export const IconMinimize = (props: any) => (
    <Icon viewBox="0 0 24 24" {...props}>
        <path d="M10.4 13.1c-.1-.1-.3-.1-.4-.1H4c-.6 0-1 .4-1 1s.4 1 1 1h3.6l-5.3 5.3c-.4.4-.4 1 0 1.4.2.2.4.3.7.3s.5-.1.7-.3L9 16.4V20c0 .6.4 1 1 1s1-.4 1-1v-6c0-.1 0-.3-.1-.4-.1-.2-.3-.4-.5-.5zM21.7 2.3c-.4-.4-1-.4-1.4 0L15 7.6V4c0-.6-.4-1-1-1s-1 .4-1 1v6c0 .1 0 .3.1.4.1.2.3.4.5.5.1.1.3.1.4.1h6c.6 0 1-.4 1-1s-.4-1-1-1h-3.6l5.3-5.3c.4-.4.4-1 0-1.4z" />
    </Icon>
);

export const IconMaximize = (props: any) => (
    <Icon viewBox="0 0 24 24" {...props}>
        <path d="M22 3v6c0 .6-.4 1-1 1s-1-.4-1-1V5.4l-5.3 5.3c-.2.2-.4.3-.7.3s-.5-.1-.7-.3c-.4-.4-.4-1 0-1.4L18.6 4H15c-.6 0-1-.4-1-1s.4-1 1-1h6c.1 0 .3 0 .4.1.2.1.4.3.5.5.1.1.1.3.1.4zM9.3 13.3L4 18.6V15c0-.6-.4-1-1-1s-1 .4-1 1v6c0 .1 0 .3.1.4.1.2.3.4.5.5.1.1.3.1.4.1h6c.6 0 1-.4 1-1s-.4-1-1-1H5.4l5.3-5.3c.4-.4.4-1 0-1.4s-1-.4-1.4 0z" />
    </Icon>
);

export const IconFuel = (props: any) => (
    <Icon viewBox="0 0 16 16" {...props}>
        <path d="M12.8 10.3c-.2-1.6-.5-6.6-.5-6.6v-.1l-2-1.8V.6c0-.3-.2-.6-.5-.6h-7c-.2 0-.5.2-.5.6v14.9c0 .3.2.6.5.6h7c.3 0 .5-.2.5-.6V7.1h.7v4.7c0 .7.1 1.3.2 1.7.1.4.4.6.7.6.4 0 .7-.2.8-.6.3-.9.1-2.9.1-3.2zM9.2 5.1c0 .4-.3.7-.6.7H4.1c-.3 0-.6-.3-.6-.7V1.8c0-.4.3-.7.6-.7h4.6c.3 0 .6.3.6.7v3.3zm3 8.1c-.1.2-.2.2-.3.2-.1 0-.2-.1-.2-.2-.1-.4-.2-.9-.2-1.5V6.5h-1.3V2.6l1.4 1.3c0 .8.3 5 .5 6.5.2.6.3 2.3.1 2.8z" />
    </Icon>
);

export const IconGearbox = (props: any) => (
    <Icon viewBox="0 0 16 16" {...props}>
        <path d="M14.9 14.4c0 .9-.7 1.6-1.6 1.6-.9 0-1.6-.7-1.6-1.6 0-.7.4-1.3 1.1-1.5V9.1H8.5v3.8c.6.2 1.1.8 1.1 1.5 0 .9-.7 1.6-1.6 1.6s-1.6-.7-1.6-1.6c0-.7.4-1.3 1.1-1.5V9.1H2.1v-6c-.6-.2-1-.8-1-1.5C1.1.7 1.8 0 2.7 0s1.6.7 1.6 1.6c0 .7-.4 1.3-1.1 1.5V8h4.3V3.1c-.7-.2-1.1-.8-1.1-1.5C6.4.7 7.1 0 8 0s1.6.7 1.6 1.6c0 .7-.4 1.3-1.1 1.5V8h4.3V3.1c-.6-.2-1.1-.8-1.1-1.5 0-.9.7-1.6 1.6-1.6.9 0 1.6.7 1.6 1.6 0 .7-.4 1.3-1.1 1.5v9.8c.7.2 1.1.8 1.1 1.5z" />
    </Icon>
);

export const IconPowertrain = (props: any) => (
    <Icon viewBox="0 0 16 16" {...props}>
        <path d="M10.5 4V3h-2v10h2v-1c0-.6.4-1 1-1s1 .4 1 1v3c0 .6-.4 1-1 1s-1-.4-1-1v-1h-5v1c0 .6-.4 1-1 1s-1-.4-1-1v-3c0-.6.4-1 1-1s1 .4 1 1v1h2V3h-2v1c0 .6-.4 1-1 1s-1-.4-1-1V1c0-.6.4-1 1-1s1 .4 1 1v1h5V1c0-.6.4-1 1-1s1 .4 1 1v3c0 .6-.4 1-1 1s-1-.4-1-1z" />
    </Icon>
);
