import { RETAILER_FRAGMENT } from './retailer.graphql';
import gql from 'graphql-tag';

export const RETAILERS_QUERY = gql`
    query RetailersQuery {
        retailers {
            ...Retailer
        }
    }
    ${RETAILER_FRAGMENT}
`;
